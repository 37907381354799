import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, LabelList } from "recharts";
import { MenuItem, Select, CircularProgress, InputLabel, FormControl } from "@mui/material";
import { getAverageResolutionTimeByMonthCustomer } from "../../features/dashboard/actions";
import WidgetContainer from "../WidgetContainer";

const AverageResolutionTime = () => {
    const dispatch = useDispatch();
    const { averageResolutionTimeByMonthCustomer } = useSelector((state) => state.dashboard);
    const [timeRange, setTimeRange] = useState(6);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        dispatch(getAverageResolutionTimeByMonthCustomer());
    }, [dispatch]);
    useEffect(() => {
        if (averageResolutionTimeByMonthCustomer?.data?.records) {
            const formattedData = averageResolutionTimeByMonthCustomer.data.records
                .slice(-timeRange)
                .map((record) => ({
                    ...record,
                    Month: formatMonth(record.Month),
                    AverageResolutionTime: parseFloat(record.AverageResolutionTime.replace(" hours", "")) || 0,
                }));
            setFilteredData(formattedData);
        }
    }, [averageResolutionTimeByMonthCustomer, timeRange]);

    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };

    const formatMonth = (monthString) => {
        const date = new Date(`${monthString}-01`);
        return date.toLocaleString("default", { month: "short", year: "numeric" });
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { Month, AverageResolutionTime, TicketCount } = payload[0].payload;
            return (
                <div style={{ backgroundColor: "#ffffff", padding: "10px" }}>
                    <p><strong>Month:</strong> {Month}</p>
                    <p><strong>Average Resolution Time:</strong> {AverageResolutionTime} hours</p>
                    <p><strong>Ticket Count:</strong> {TicketCount}</p>
                </div>
            );
        }
        return null;
    };
    return averageResolutionTimeByMonthCustomer.loading ? (
        <CircularProgress color="secondary" />
    ) : (
        <WidgetContainer title="Average Resolution Time">

            <FormControl sx={{ minWidth: 100, maxWidth: 120, marginBottom: 2, marginLeft: 36 }}>
                <InputLabel>Time Range</InputLabel>
                <Select value={timeRange} onChange={handleTimeRangeChange} label="Time Range">
                    <MenuItem value={6}> 6 months</MenuItem>
                    <MenuItem value={12}>1 year</MenuItem>
                    <MenuItem value={24}>2 years</MenuItem>
                </Select>
            </FormControl>
            {filteredData.length > 0 ? (
                <ComposedChart
                    width={700}
                    height={420}
                    data={filteredData}
                    margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                >
                    <CartesianGrid stroke="#d6d6d6" />
                    <XAxis dataKey="Month" tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }}
                        interval={0}
                        padding={{ left: 20, right: 20 }} />
                    <YAxis label={{ value: "Hours", angle: -90, position: "insideLeft" }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={() => null} />
                    <Bar dataKey="AverageResolutionTime" barSize={30} fill="#fd7f6f" >
                    </Bar>
                </ComposedChart>
            ) : (
                null
            )}
        </WidgetContainer>
    );
};

export default AverageResolutionTime;
