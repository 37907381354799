import { createSlice } from "@reduxjs/toolkit";
import { updateUser, deleteUser, getAppUsers, createUser, emailActiveAppUsers } from "./actions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  appUsersList: {
    data: [],
    loading: false,
  },
};

const commonSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [createUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getAppUsers.pending]: (state) => {
      state.appUsersList.loading = true;
      state.error = null;
    },
    [getAppUsers.fulfilled]: (state, { payload }) => {
      state.appUsersList.loading = false;
      state.appUsersList.data = payload;
    },
    [getAppUsers.rejected]: (state, { payload }) => {
      state.appUsersList.loading = false;
      state.error = payload;
    },
    [emailActiveAppUsers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [emailActiveAppUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [emailActiveAppUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default commonSlice.reducer;
