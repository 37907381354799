import { createSlice } from "@reduxjs/toolkit";
import {
  getServiceHoursByYear4Years,
  getServiceHoursForState4Years,
  getServiceHoursByMonths,
  getServiceHoursForCustomerByMachine4Years,
  getTotalServiceHoursForCustomerInStateByYear,
  getServiceHoursAllStatesLast4Years,
  sendWeeklyServiceReports,
  sendWarrantyCallsDetails,
  sendMachineList,
  getServiceTicketsStatusByTechId,
  getTotalSVCHoursByCustomer,
  getServiceTicketRating,
  getMonthlyCustomerRating,
  getAverageResolutionTimeByMonthCustomer,
  getAverageResponseTimeByMonth

} from "./actions";

const initialState = {
  loading: false,
  monthlyCustomerRating: {
    loading: false,
    data: {},
    error: null,
  },
  serviceHoursByYear4Years: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursByMonths: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursForCustomerByMachine4Years: [],
  serviceHoursByCustomerByYear: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursForState4Years: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursByTicketCategoryForCustomer: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursAllStatesLast4Years: {
    loading: false,
    data: [],
    error: null,
  },
  serviceTicketsStatusByTech: {
    loading: false,
    data: [],
    error: null,
  },
  serviceTicketRatingForCustomer: {
    loading: false,
    data: [],
    error: null,
  },
  averageResolutionTimeByMonthCustomer: {
    loading: false,
    data: [],
    error: null,
  },
  averageResponseTimeByMonth: {
    loading: false,
    data: { records: [] },
    error: null,
  },
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [sendWarrantyCallsDetails.pending]: (state) => {
      state.loading = true;
    },
    [sendWarrantyCallsDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWarrantyCallsDetails.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWeeklyServiceReports.pending]: (state) => {
      state.loading = true;
    },
    [sendWeeklyServiceReports.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWeeklyServiceReports.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [sendMachineList.pending]: (state) => {
      state.loading = true;
    },
    [sendMachineList.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendMachineList.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getMonthlyCustomerRating.pending]: (state) => {
      state.monthlyCustomerRating.loading = true;
    },
    [getMonthlyCustomerRating.fulfilled]: (state, { payload }) => {
      state.monthlyCustomerRating.loading = false;
      state.monthlyCustomerRating.data = payload;
    },
    [getMonthlyCustomerRating.rejected]: (state, { payload }) => {
      state.monthlyCustomerRating.loading = false;
      state.monthlyCustomerRating.error = payload;
    },
    [getServiceHoursByYear4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByYear4Years.loading = true;
      state.serviceHoursByYear4Years.error = null;
    },
    [getServiceHoursByYear4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByYear4Years.loading = false;
      state.serviceHoursByYear4Years.data = payload;
    },
    [getServiceHoursByYear4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByYear4Years.loading = false;
      state.serviceHoursByYear4Years.error = payload;
    },
    [getServiceHoursByMonths.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByMonths.loading = true;
      state.serviceHoursByMonths.error = null;
    },
    [getServiceHoursByMonths.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByMonths.loading = false;
      state.serviceHoursByMonths.data = payload;
    },
    [getServiceHoursByMonths.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByMonths.loading = false;
      state.serviceHoursByMonths.error = payload;
    },
    [getServiceHoursForCustomerByMachine4Years.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServiceHoursForCustomerByMachine4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForCustomerByMachine4Years = payload;
    },
    [getServiceHoursForCustomerByMachine4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getTotalServiceHoursForCustomerInStateByYear.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByCustomerByYear.loading = true;
      state.serviceHoursByCustomerByYear.error = null;
    },
    [getTotalServiceHoursForCustomerInStateByYear.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByCustomerByYear.loading = false;
      state.serviceHoursByCustomerByYear.data = payload;
    },
    [getTotalServiceHoursForCustomerInStateByYear.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByCustomerByYear.loading = false;
      state.serviceHoursByCustomerByYear.error = payload;
    },
    [getServiceHoursForState4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursForState4Years.loading = true;
      state.serviceHoursForState4Years.error = null;
    },
    [getServiceHoursForState4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForState4Years.loading = false;
      state.serviceHoursForState4Years.data = payload;
    },
    [getServiceHoursForState4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForState4Years.loading = false;
      state.serviceHoursForState4Years.error = payload;
    },
    [getServiceHoursAllStatesLast4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursAllStatesLast4Years.loading = true;
      state.serviceHoursAllStatesLast4Years.error = null;
    },
    [getServiceHoursAllStatesLast4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursAllStatesLast4Years.loading = false;
      state.serviceHoursAllStatesLast4Years.data = payload;
    },
    [getServiceHoursAllStatesLast4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursAllStatesLast4Years.loading = false;
      state.serviceHoursAllStatesLast4Years.error = payload;
    },
    [getServiceTicketsStatusByTechId.pending]: (state) => {
      state.loading = true;
      state.serviceTicketsStatusByTech.loading = true;
      state.serviceTicketsStatusByTech.error = null;
    },
    [getServiceTicketsStatusByTechId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsStatusByTech.loading = false;
      state.serviceTicketsStatusByTech.data = payload;
    },
    [getServiceTicketsStatusByTechId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsStatusByTech.loading = false;
      state.serviceTicketsStatusByTech.error = payload;
    },
    [getTotalSVCHoursByCustomer.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByTicketCategoryForCustomer.loading = true;
      state.serviceHoursByTicketCategoryForCustomer.error = null;
    },
    [getTotalSVCHoursByCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByTicketCategoryForCustomer.loading = false;
      state.serviceHoursByTicketCategoryForCustomer.data = payload;
    },
    [getTotalSVCHoursByCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByTicketCategoryForCustomer.loading = false;
      state.serviceHoursByTicketCategoryForCustomer.error = payload;
    },
    [getServiceTicketRating.pending]: (state) => {
      state.loading = true;
      state.serviceTicketRatingForCustomer.loading = true;
      state.serviceTicketRatingForCustomer.error = null;
    },
    [getServiceTicketRating.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketRatingForCustomer.loading = false;
      state.serviceTicketRatingForCustomer.data = payload;
    },
    [getServiceTicketRating.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketRatingForCustomer.loading = false;
      state.serviceTicketRatingForCustomer.error = payload;
    },
    [getAverageResolutionTimeByMonthCustomer.pending]: (state) => {
      state.loading = true;
      state.averageResolutionTimeByMonthCustomer.loading = true;
      state.averageResolutionTimeByMonthCustomer.error = null;
    },
    [getAverageResolutionTimeByMonthCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.averageResolutionTimeByMonthCustomer.loading = false;
      state.averageResolutionTimeByMonthCustomer.data = payload;
    },
    [getAverageResolutionTimeByMonthCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.averageResolutionTimeByMonthCustomer.loading = false;
      state.averageResolutionTimeByMonthCustomer.error = payload;
    },
    [getAverageResponseTimeByMonth.pending]: (state) => {
      state.loading = true;
      state.averageResponseTimeByMonth.loading = true;
      state.averageResponseTimeByMonth.error = null;
    },
    [getAverageResponseTimeByMonth.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.averageResponseTimeByMonth.loading = false;
      state.averageResponseTimeByMonth.data = payload;
    },
    [getAverageResponseTimeByMonth.rejected]: (state, { payload }) => {
      state.loading = false;
      state.averageResponseTimeByMonth.loading = false;
      state.averageResponseTimeByMonth.error = payload;
    },
  },

});

export default authSlice.reducer;
