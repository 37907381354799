import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Typography, Paper, Autocomplete, TextField, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Label, ResponsiveContainer } from "recharts";
import { getAppUsers } from "../../features/users/actions";
import { readAsyncStorageValues } from "../../features/common/actions";
import { getServiceTicketsStatusByTechId } from "../../features/dashboard/actions";

const ByServiceEngineer = () => {
  const dispatch = useDispatch();
  const { serviceTicketsStatusByTech } = useSelector((state) => state.dashboard);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { appUsersList } = useSelector((state) => state.users);
  const { userCurrentRole } = useSelector((state) => state.auth);

  const [ticketsCount, setTickets] = useState([]);
  const [serviceEngineerList, setServiceEngineerList] = useState([]);
  const [serviceUsers, setServiceUsers] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(3, "months").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAppUsers());
  }, [dispatch]);

  useEffect(() => {
    if (asyncStorageValues && asyncStorageValues.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const serviceUsers = appUsersList.data
        .filter((obj) => obj.userRole === roles.ROLE_SERVICE_ENGINEER && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_SERVICE_ENGINEER) {
        setServiceEngineerList(serviceUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setServiceEngineerList(serviceUsers);
      }
    }
  }, [appUsersList, asyncStorageValues, userCurrentRole]);

  useEffect(() => {
    const payload = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      userId: serviceUsers?.userId || null,
    };
    dispatch(getServiceTicketsStatusByTechId(payload));
  }, [dispatch, serviceUsers, startDate, endDate]);

  useEffect(() => {
    if (asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS) {
      const { ASSIGNED, COMPLETED } = asyncStorageValues.allTypes.SERVICE_TICKET_STATUS;
      const noDataList = [
        { count: 0, status: ASSIGNED },
        { count: 0, status: COMPLETED },
      ];
      const dataCount = serviceTicketsStatusByTech.data.length ? serviceTicketsStatusByTech.data : noDataList;
      setTickets(dataCount);
    }
  }, [serviceTicketsStatusByTech, asyncStorageValues]);

  const handleStartDateChange = (date) => {
    setStartDate(date.toDate());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date.toDate());
  };

  return (
    <Box elevation={8} sx={{ padding: 1, overflow: "hidden", width: "100%", height: 500 }}>
      <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Tickets by Service Engineer
      </Typography>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={serviceUsers || null}
          onChange={(e, newValue) => setServiceUsers(newValue)}
          options={serviceEngineerList}
          getOptionLabel={(option) => option.name}
          sx={{ minWidth: 800, maxWidth: 1000 }}
          renderInput={(params) => <TextField {...params} label="All Service Engineers" />}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(startDate)}
            onChange={handleStartDateChange}
            label="Start Date"
            format={"DD MMM YYYY"}
            disableFuture
            sx={{ width: 200, paddingRight: 5 }}
          />
          <DatePicker
            value={moment(endDate)}
            onChange={handleEndDateChange}
            label="End Date"
            format={"DD MMM YYYY"}
            disableFuture
            sx={{ width: 200, paddingRight: 5 }}
          />
        </LocalizationProvider>
      </div>

      <div style={{ height: 320, width: "100%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={ticketsCount}
            margin={{
              top: 20,
              right: 10,
              bottom: 10,
              left: 10,
            }}
          >
            <CartesianGrid stroke="#d6d6d6" />
            <XAxis dataKey="status" />
            <YAxis allowDecimals={false}>
              <Label
                style={{
                  textAnchor: "middle",
                }}
                position={"left"}
                angle={270}
                value={"Count"}
              />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar dataKey="count" name="Tickets" barSize={35} label={{ position: "top" }} isAnimationActive={false} fill="#1865a1" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
};

export default ByServiceEngineer;
