import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Bar, XAxis, YAxis, CartesianGrid, Legend, ComposedChart } from "recharts";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Grid, Tooltip, Button, Stack } from "@mui/material";
import moment from "moment";
import { getServiceTicketsForCustomer } from "../../features/customers/actions";
import { exportToCsv } from "../../utils/utilFunctions";
import { getCustomersWithServiceTickets } from "../../features/servicetickets/actions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getTotalSVCHoursByCustomer, getServiceTicketRating } from "../../features/dashboard/actions";
import ServiceHoursByTicketCategoryForCustomer from "../../components/ServiceHoursByTicketCategoryForCustomer";
import ServiceTicketRatingForCustomer from "../../components/ServiceTicketRatingForCustomer";

const AllServiceTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceTicketsForCustomer } = useSelector((state) => state.customers);
  const { customersWithServiceTickets } = useSelector((state) => state.serviceTickets);
  const { serviceHoursByTicketCategoryForCustomer, loading: serviceHoursLoading } = useSelector((state) => state.dashboard);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { serviceTicketRatingForCustomer } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
  }, [dispatch]);

  useEffect(() => {
    if (customersWithServiceTickets.data.length) {
      setSelectedCustomer(customersWithServiceTickets.data[0]);
    }
  }, [customersWithServiceTickets]);
  useEffect(() => {
    if (selectedCustomer?.customerId) {
      dispatch(getServiceTicketRating({ customerId: selectedCustomer.customerId }));
    }
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    const fetchCustomerServiceHours = async () => {
      try {
        if (selectedCustomer?.customerId) {
          await dispatch(getTotalSVCHoursByCustomer({ customerId: selectedCustomer.customerId }));
        } else {
          console.error("customerId is undefined");
        }
      } catch (error) {
        console.error("Error fetching service hours:", error);
      } finally {
        setLoading(false);
      }
    };
    if (selectedCustomer) {
      setLoading(true);
      fetchCustomerServiceHours();
    }
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    const fetchCustomerServiceTickets = async () => {
      try {
        await dispatch(getServiceTicketsForCustomer(selectedCustomer.customerId));
      } catch (error) {
        console.error("Error fetching service tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCustomer) {
      setLoading(true);
      fetchCustomerServiceTickets();
    }
  }, [selectedCustomer]);

  const onCustomerChange = (event, newValue) => setSelectedCustomer(newValue);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="View">
          <Button
            startIcon={<ZoomInIcon />}
            color="primary"
            size="large"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/all-service-ticket-view/" + params.row.serviceTicketId);
            }}
          />
        </Tooltip>
        {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER &&
          asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
            <Tooltip title="Edit">
              <Button
                startIcon={<EditIcon />}
                color="primary"
                size="large"
                style={{ marginLeft: -12, fontWeight: "bold" }}
                onClick={() => {
                  navigate("/all-service-ticket-view/" + params.row.serviceTicketId, { state: { editMode: true } });
                }}
              />
            </Tooltip>
          )}
      </strong>
    );
  };

  const columns = [
    { field: "serviceTicketId", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.2,
      sortComparator: dateComparator,
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.2,
      sortComparator: dateComparator,
    },
    { field: "assigner", headerName: "Created By", width: 250 },
    { field: "Service Engineer", headerName: "Service Engineer", width: 250 },
    { field: "ticketCategory", headerName: "Category", width: 250 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Service Ticket Id, Date Created, Date Closed, Service Engineer,Ticket Category, Status"];
    const keys = ["serviceTicketId", "dateCreated", "closedDate", "Service Engineer", "ticketCategory", "status"];
    exportToCsv(headers, keys, serviceTicketsForCustomer.data, "Service_Tickets");
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mx={3} my={2}>
          {customersWithServiceTickets.loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Autocomplete
              disablePortal
              id="customerdropdown"
              options={customersWithServiceTickets.data.slice()}
              onChange={onCustomerChange}
              value={selectedCustomer}
              getOptionLabel={(option) => option.customerName}
              renderInput={(params) => <TextField {...params} label="Customer Name" sx={{ width: 660, marginTop: "10px" }} />}
            />
          )}

          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
            onClick={downloadCsv}
            disabled={serviceTicketsForCustomer.data.length ? false : true}
          >
            Export to CSV
          </Button>
        </Stack>

        <Stack mt={3} spacing={1}>
          <Stack direction="row" ml={3} mr={3} gap={3} flex={1}>
            <Box flex={1}>
              {selectedCustomer && serviceHoursByTicketCategoryForCustomer?.data?.length ? (
                <ServiceHoursByTicketCategoryForCustomer selectedCustomer={selectedCustomer} />
              ) : (
                serviceHoursLoading && <CircularProgress color="secondary" />
              )}
            </Box>
            <Box flex={1}>
              {selectedCustomer ? (
                <ServiceTicketRatingForCustomer selectedCustomer={selectedCustomer} />
              ) : (
                serviceHoursLoading && <CircularProgress color="secondary" />
              )}
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ height: 700, px: 3, mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
          {loading || serviceTicketsForCustomer.loading ? (
            <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
          ) : (
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGrid
                rows={selectedCustomer ? serviceTicketsForCustomer.data : []}
                getRowId={(row) => row.serviceTicketId}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                localeText={{ noRowsLabel: "No tickets." }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AllServiceTickets;
