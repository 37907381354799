import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const getAppUsers = createAsyncThunk("users/getAppUsers", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`/serviceapi/appUsers`, payload);
    if (data) return data.sort((a, b) => a.name.localeCompare(b.name));
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const createUser = createAsyncThunk("users/createUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`/serviceapi/createUser`, payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateUser = createAsyncThunk("users/updateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`/serviceapi/updateUser`, payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`/serviceapi/deleteUser`, payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const emailActiveAppUsers = createAsyncThunk("users/emailActiveAppUsers", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`/serviceapi/emailActiveAppUsers`, payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
