import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const createTemplate = createAsyncThunk("machines/createTemplate", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/createTemplate", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateTemplate = createAsyncThunk("machines/updateTemplate", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/updateTemplate", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllTemplates = createAsyncThunk("templates/getAllTemplates", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get("/serviceapi/getAllTemplates", payload);
    const formattedTemplateList = response?.data
      .map((item) => ({
        ...item,
        sections: item.sections.map((section, index) => ({ ...section, index })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return { ...response, data: [...formattedTemplateList] };
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
