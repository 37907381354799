import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ComposedChart, Label, ResponsiveContainer } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { getMonthlyCustomerRating } from "../../../features/dashboard/actions";
import {
  Typography,
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  Button,
  Tooltip as ButtonTooltip,
} from "@mui/material";
import WidgetContainer from "../../../components/WidgetContainer";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import ZoomIn from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";

const CustomerRatings = () => {
  const targetRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { monthlyCustomerRating } = useSelector((state) => state.dashboard);
  const [selectedYearsCount, setSelectedYearsCount] = useState({ label: "3 months", value: 3 });
  const [customerRatingsData, setCustomerRatingsData] = useState([]);
  const [serviceTicketList, setServiceTicketList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rating, setRating] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      dispatch(getMonthlyCustomerRating());
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (monthlyCustomerRating?.data?.monthlyRatingDetailsMap && selectedYearsCount) {
      const ratingList = monthlyCustomerRating?.data?.monthlyRatingDetailsMap;
      const latestDate = moment(Object.keys(ratingList).sort().reverse()[0] + "-01");
      // Collect and slice entries
      const slicedEntries = Object.keys(ratingList)
        .map((key) => ({ date: moment(key + "-01"), values: ratingList[key], month: key }))
        .filter((entry) => entry.date.isSameOrBefore(latestDate, "month"))
        .slice(-parseInt(selectedYearsCount?.value || 3))
        .map((entry) => ({ ...entry.values, month: entry.month }));
      setSelectedMonth(slicedEntries[0].month);

      setCustomerRatingsData(slicedEntries);
    }
  }, [monthlyCustomerRating, selectedYearsCount]);

  useEffect(() => {
    if (selectedMonth) {
      const ticketListFromMap = monthlyCustomerRating?.data?.monthlyRatingTktDetailsMap[selectedMonth];
      if (ticketListFromMap.length)
        setServiceTicketList([...ticketListFromMap].sort((a, b) => a.customerName.localeCompare(b.customerName)));
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedMonth) {
      const ticketListFromMap = monthlyCustomerRating?.data?.monthlyRatingTktDetailsMap[selectedMonth];
      if (rating) {
        if (ticketListFromMap.length)
          setServiceTicketList(
            [...ticketListFromMap].filter((item) => item.rating == rating).sort((a, b) => a.customerName.localeCompare(b.customerName))
          );
      } else setServiceTicketList(ticketListFromMap || []);
    }
  }, [rating]);

  const handleCountChange = (event, newValue) => {
    setSelectedYearsCount(newValue);
  };

  const renderDetailsButton = (params) => {
    return (
      <div>
        <ButtonTooltip title="View">
          <Button
            startIcon={<ZoomIn />}
            color="primary"
            size="large"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/all-service-ticket-view/" + params.row.tktId);
            }}
          ></Button>
        </ButtonTooltip>
      </div>
    );
  };

  const columns = [
    { field: "tktId", headerName: "Ticket Id", flex: 0.2 },
    { field: "customerName", headerName: "Customer Name", flex: 0.3 },
    { field: "rating", headerName: "Rating", flex: 0.2 },
    { field: "state", headerName: "State", flex: 0.2 },
    {
      field: "action",
      headerName: "",
      flex: 0.1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const CustomNoRowsOverlay = () => (
    <Box sx={{ height: 6 }}>
      <Typography style={{ marginLeft: 10 }}>No items.</Typography>
    </Box>
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleBarClick = (index) => {
    if (customerRatingsData[index] && customerRatingsData[index]?.month) {
      setSelectedMonth(customerRatingsData[index].month);
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const onChangeRating = (event, newValue) => setRating(newValue);

  return monthlyCustomerRating.loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div>
      <div>
        <WidgetContainer
          title={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="h4" sx={{ mb: 3, mt: 1, ml:2 }}>
                Customer Ratings
              </Typography>
              <div style={{ textAlign: "center", width: 180 }}>
                <Autocomplete
                  defaultValue="3 months"
                  value={selectedYearsCount?.label}
                  onChange={handleCountChange}
                  options={[
                    { label: "3 months", value: 3 },
                    { label: "6 months", value: 6 },
                    { label: "1 Year", value: 12 },
                    { label: "2 Year", value: 24 },
                  ]}
                  renderInput={(params) => <TextField {...params} label="Range" />}
                />
              </div>
            </div>
          }
        >
          <ResponsiveContainer width="100%" height={500}>
            <ComposedChart data={customerRatingsData}>
              <CartesianGrid stroke="#d6d6d6" />
              <XAxis dataKey="month" angle={-45} textAnchor="end" interval={0} height={70} />
              <YAxis />
              <Legend />
              <Tooltip />
              {[1, 2, 3, 4, 5].map((rating) => (
                <Bar
                  key={rating}
                  dataKey={String(rating)}
                  barSize={10}
                  fill={rating === 1 ? "#5e5d5d" : rating === 2 ? "orange" : rating === 3 ? "brown" : rating === 4 ? "olive" : "#0047AB"}
                  onClick={(event, index) => handleBarClick(index)}
                  cursor={"pointer"}
                >
                  {customerRatingsData.map((entry, index) => (
                    <Label key={`label-${index}`} content={entry.month} position="insideTop" />
                  ))}
                </Bar>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </WidgetContainer>
      </div>
      <div ref={targetRef}>
        <Stack sx={{ mx: 3, mt: 4 }} direction={"row"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"space-between"}>

        <Typography variant="h4" ml={2}>
            Month : {selectedMonth}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              disabled={selectedMonth ? false : true}
              style={{ minWidth: 450, maxWidth: "100%" }}
              label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <Close />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          
            <Autocomplete
              disabled={selectedMonth ? false : true}
              style={{ minWidth: 150, marginRight: 3 }}
              disablePortal
              id="ratingdropdown"
              options={[1, 2, 3, 4, 5]}
              onChange={onChangeRating}
              value={rating}
              renderInput={(params) => <TextField {...params} label="Rating" variant="outlined" />}
            />
          </Box>
        </Stack>
        <div style={{ height: 650, overflow: "auto", width: "98%", paddingLeft: "2%", paddingTop: 3, marginTop: 8 }}>
          <DataGrid
            rows={serviceTicketList.filter((ticket) => Object.values(ticket).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))}
            columns={columns}
            getRowId={(row) => row.tktId}
            pageSize={5}
            style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerRatings;
