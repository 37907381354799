import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import WidgetContainer from "../WidgetContainer";
import { readAsyncStorageValues } from "../../features/common/actions";
import { Paper, Stack, Typography } from "@mui/material";
const ServiceHoursByTicketCategoryForCustomer = () => {
  const dispatch = useDispatch();
  const { asyncStorageValues } = useSelector((state) => state.common);
  const abbreviations = asyncStorageValues.allTypes.ABBREVIATIONS;
  const { serviceHoursByTicketCategoryForCustomer, loading } = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
  }, [dispatch]);
  const chartData = Array.isArray(serviceHoursByTicketCategoryForCustomer.data)
    ? serviceHoursByTicketCategoryForCustomer.data.map((item) => {
        return {
          category: abbreviations[item.ticketCategory] || item.ticketCategory,
          hours: parseFloat(item.totalHourSpent) || 0,
        };
      })
    : [];
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Paper elevation={8} sx={{ padding: 1, height: 550, overflow: "hidden" }}>
      {/* <WidgetContainer title="Service Hours by Ticket Category"></WidgetContainer> */}
      <Stack alignItems={"center"} py={3}>
        <Typography fontWeight={"700"}>Service Hours by Ticket Category</Typography>
      </Stack>
      <div style={{ height: 450 }}>
        <ResponsiveContainer width="99%" height="100%">
          <ComposedChart margin={{ top: 25, right: 20, bottom: 20, left: 20 }} data={chartData} style={{ background: "transparent" }}>
            <CartesianGrid stroke="transparent" />
            <XAxis dataKey="category" interval={0} tick={{ fontSize: 12, angle: -45, textAnchor: "end" }} />
            <YAxis domain={[0, "dataMax + 10"]} tickFormatter={(value) => Math.round(value)} />
            <Tooltip />
            <Legend content={() => null} />
            <Bar
              dataKey="hours"
              barSize={30}
              fill="#fd7f6f"
              minPointSize={10}
              label={{
                position: "top",
                fill: "#000",
                fontSize: 12,
                formatter: (value) => `${value} hrs`,
              }}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};
export default ServiceHoursByTicketCategoryForCustomer;
