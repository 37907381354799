import { createSlice } from "@reduxjs/toolkit";
import { readAsyncStorageValues, getStatesWithServiceTickets, getStatesWithCustomers } from "./actions";

const initialState = {
  loading: false,
  asyncStorageValues: {},
  indianStateDropdownOptions: [],
  indianStateDropdownOptionsCodeOnly: [],
  machineModels: [],
  statesWithServiceTickets: {
    data: [],
    loading: false,
  },
  statesWithCustomers: {
    data: [],
    loading: false,
  },
  error: null,
  success: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: {
    [readAsyncStorageValues.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readAsyncStorageValues.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.asyncStorageValues = payload.asyncStorageValues;
      state.indianStateDropdownOptions = payload.indianStateDropdownOptions;
      state.indianStateDropdownOptionsCodeOnly = payload.indianStateDropdownOptionsCodeOnly;
      state.machineModels = payload.machineModels;
      state.appConstants = payload.appConstants;
    },
    [readAsyncStorageValues.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getStatesWithCustomers.pending]: (state) => {
      state.statesWithCustomers.loading = true;
      state.error = null;
    },
    [getStatesWithCustomers.fulfilled]: (state, { payload }) => {
      state.statesWithCustomers.loading = false;
      state.statesWithCustomers.data = payload;
    },
    [getStatesWithCustomers.rejected]: (state, { payload }) => {
      state.statesWithCustomers.loading = false;
    },
    [getStatesWithServiceTickets.pending]: (state) => {
      state.statesWithServiceTickets.loading = true;
      state.statesWithServiceTickets.error = null;
    },
    [getStatesWithServiceTickets.fulfilled]: (state, { payload }) => {
      state.statesWithServiceTickets.loading = false;
      state.statesWithServiceTickets.data = payload;
    },
    [getStatesWithServiceTickets.rejected]: (state, { payload }) => {
      state.statesWithServiceTickets.loading = false;
      state.statesWithServiceTickets.error = payload;
    },
  },
});

export default commonSlice.reducer;
