import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, LabelList } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAverageResponseTimeByMonth } from "../../features/dashboard/actions";
import WidgetContainer from "../WidgetContainer";

const AverageResponseTime = () => {
    const dispatch = useDispatch();
    const { data = { records: [] }, loading = true } = useSelector((state) => state.dashboard.averageResponseTimeByMonth || {});

    const [timeRange, setTimeRange] = useState(6);

    useEffect(() => {
        dispatch(getAverageResponseTimeByMonth());
    }, [dispatch]);

    const formatMonth = (monthString) => {
        const date = new Date(`${monthString}-01`);
        return date.toLocaleString("default", { month: "short", year: "numeric" });
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { Month, AverageResponseTime, TicketCount } = payload[0].payload;
            return (
                <div style={{ backgroundColor: "#ffffff", padding: "10px" }}>
                    <p><strong>Month:</strong> {Month}</p>
                    <p><strong>Average Response Time:</strong> {AverageResponseTime} hours</p>
                    <p><strong>Ticket Count:</strong> {TicketCount}</p>
                </div>
            );
        }
        return null;
    };
    const getFilteredData = () => {
        const currentDate = new Date();
        const filteredRecords = data.records.filter((record) => {
            const recordDate = new Date(`${record.Month}-01`);
            const monthsDifference = (currentDate.getFullYear() - recordDate.getFullYear()) * 12 + (currentDate.getMonth() - recordDate.getMonth());
            return monthsDifference < timeRange;
        });

        return filteredRecords.map((record) => ({
            ...record,
            Month: formatMonth(record.Month),
            AverageResponseTime: parseFloat(record.AverageResponseTime.replace(" hours", "")) || 0,
        }));
    };

    const handleChange = (event) => {
        setTimeRange(event.target.value);
    };

    const formattedData = getFilteredData();

    return (
        <WidgetContainer title="Average Response Time">
            <FormControl sx={{ minWidth: 100, maxWidth: 120, marginBottom: 2, marginLeft: 37 }}>
                <InputLabel>Time Range</InputLabel>
                <Select value={timeRange} onChange={handleChange} label="Time Range">
                    <MenuItem value={6}> 6 Months</MenuItem>
                    <MenuItem value={12}> 1 Year</MenuItem>
                    <MenuItem value={24}> 2 Years</MenuItem>
                </Select>
            </FormControl>

            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                formattedData.length > 0 ? (
                    <ComposedChart
                        width={700}
                        height={420}
                        data={formattedData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 40,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#d6d6d6" />
                        <XAxis dataKey="Month"
                            interval={0}
                            tick={{
                                fontSize: 12,
                                angle: -45,
                                textAnchor: 'end'
                            }}
                            padding={{ left: 20, right: 20 }} />
                        <YAxis
                            label={{
                                value: "Hours",
                                angle: -90,
                                position: "insideLeft",
                            }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={() => null} />
                        <Bar dataKey="AverageResponseTime" barSize={30} fill="#fd7f6f" >
                        </Bar>
                    </ComposedChart>
                ) : (
                    null
                )
            )}
        </WidgetContainer>
    );
};

export default AverageResponseTime;
