import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { getServiceHoursByYear4Years } from "../../features/dashboard/actions";
import WidgetContainer from "../WidgetContainer";

const ServiceHoursBy4YearsNationalBar = () => {
  const dispatch = useDispatch();
  const { serviceHoursByYear4Years } = useSelector((state) => state.dashboard);
  useEffect(() => {
    const fetch = async () => {
      dispatch(getServiceHoursByYear4Years());
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    console.log(serviceHoursByYear4Years);
  }, [serviceHoursByYear4Years]);
  return serviceHoursByYear4Years.loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <WidgetContainer title="Service Hours Last 5 Years National">
      <ComposedChart
        width={350}
        height={420}
        data={serviceHoursByYear4Years.data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <Legend content={() => null} />
        <CartesianGrid stroke="#d6d6d6" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="service_hours" barSize={30} fill="#fd7f6f" label={{ position: "top" }} name=" " />
      </ComposedChart>
    </WidgetContainer>
  );
};

export default ServiceHoursBy4YearsNationalBar;
