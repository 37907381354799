import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";
import moment from "moment";

export const getSalesCallsBySalesPerson = createAsyncThunk("sales/getSalesCallsBySalesPerson", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/salesapi/getSalesCallsBySalesPerson?salesPersonId=${payload.salesPersonId}`);
    if (data.length)
      return data
        .map((item) => ({
          ...item,
          visitedDate: item.visitedDate ? moment(item.visitedDate).format("DD MMM YYYY") : "N.A",
        }))
        .sort((a, b) => a.customerName.localeCompare(b.customerName));
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getSalesCallsForCustomer = createAsyncThunk("sales/getSalesCallsForCustomer", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get(`/salesapi/getSalesCallsForCustomer?customerId=${payload.customerId}`);

    if (response?.data.length)
      return response?.data
        .map((item) => ({
          ...item,
          visitedDate: item.visitedDate ? moment(item.visitedDate).format("DD MMM YYYY") : "N.A",
          nextMeetingDate: item.nextMeetingDate ? moment(item.nextMeetingDate).format("DD MMM YYYY") : "N.A",
        }))
        .sort((a, b) => moment(b.visitedDate).diff(moment(a.visitedDate)));

    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
