import React, { useEffect, useState, useRef } from "react";
import api from "../../app/api";
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";

const AuthorizedVideo = ({ path, width, height, zoomLevel, setVideo, onClick, size = "" }) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [loadProgress, setLoadProgress] = useState(null);
  const videoRef = useRef();
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        setLoadProgress(null);
        const percentTotalFraction = size ? size / 1048576 : 0;
        const roundedNumTotal = Math.round(percentTotalFraction * 100) / 100;
        const response = await api.get(`/serviceapi/writeImageToClient?location=${path}`, {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const percentLoadedFraction = progressEvent.loaded / 1048576;
            if (size) {
              const roundedNum = Math.round(percentLoadedFraction * 100) / 100;
              const loadedPercent = (roundedNum / roundedNumTotal) * 100;
              const roundedLoadedPercent = Math.round(loadedPercent * 10) / 10;
              setLoadProgress(roundedLoadedPercent);
            } else {
              const roundedNum = Math.ceil(Math.round(percentLoadedFraction * 10) / 10);
              setLoadProgress(roundedNum);
            }
          },
        });
        const videoUrl = URL.createObjectURL(response.data);
        setVideo(videoUrl);
        setVideoUrl(videoUrl);
        setLoadProgress(null);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideo();
  }, [path]);

  const VideoStyle = {
    width: 900,
    height: 500,
    display: "flex",
  };
  if (loadProgress) {
    return (
      <div>
        {size ? (
          <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: -2 }}>
            <CircularProgress variant="determinate" color="secondary" size={50} sx={{ mb: 1 }} value={loadProgress} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            >{`${loadProgress} %`}</Typography>
          </Stack>
        ) : (
          <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: -2 }}>
            <CircularProgress color="secondary" size={50} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", mt: -1 }}
            >{`${loadProgress} MB`}</Typography>
            <Typography mt={1}>Loading...</Typography>
          </Stack>
        )}
      </div>
    );
  }
  return videoUrl ? (
    <video controls style={VideoStyle} onClick={onClick} ref={videoRef}>
      <source src={videoUrl} type="video/mp4" />
      {/* Add additional <source> elements for other video formats if needed */}
      Your browser does not support the video tag.
    </video>
  ) : null;
};

export default AuthorizedVideo;
