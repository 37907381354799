import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import { CircularProgress, Typography, Box, Paper, Autocomplete, TextField, Stack } from "@mui/material";
import { getTotalServiceHoursForCustomerInStateByYear } from "../../features/dashboard/actions";
import { readCustomersByState } from "../../features/customers/actions";
import { getStatesWithServiceTickets, getStatesWithCustomers } from "../../features/common/actions";

const ServiceHoursBy4YearsByStateForCustomerBar = () => {
  const dispatch = useDispatch();
  const [indianStates, setIndianStates] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [graphOptions, setGraphOptions] = useState([]);

  const [selectedIndianState, setSelectedIndianState] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const { serviceHoursByCustomerByYear } = useSelector((state) => state.dashboard);

  const { loading: commonLoading, indianStateDropdownOptions, statesWithServiceTickets } = useSelector((state) => state.common);
  const { loading: customersLoading, customersByState } = useSelector((state) => state.customers);


  useEffect(() => {
    dispatch(getStatesWithServiceTickets());
  }, [dispatch]);
  useEffect(() => {
    const fetch = async () => {
      dispatch(readCustomersByState({ indianStateCode: selectedIndianState.value }));
      dispatch(getTotalServiceHoursForCustomerInStateByYear({ state: selectedIndianState.value }));
    };

    fetch();
  }, [dispatch, selectedIndianState, selectedCustomer]);


  useEffect(() => {
    if (!commonLoading && statesWithServiceTickets.data) {
      const initialState = statesWithServiceTickets.data.find(
        (state) => state.label !== "Andaman and Nicobar Islands" && state.value !== "AN"
      );
      setSelectedIndianState(initialState); // Sets the first state that’s not Andaman and Nicobar Islands
      setSelectedCustomer(null); 
      if (initialState) {
        // Fetch customers for the default state
        dispatch(readCustomersByState({ indianStateCode: initialState.value }));
        dispatch(getTotalServiceHoursForCustomerInStateByYear({ state: initialState.value }));
      }


    }
  }, [commonLoading, statesWithServiceTickets, dispatch]);



  useEffect(() => {
    if (!commonLoading) setIndianStates(statesWithServiceTickets.data);

    if (!customersLoading && !selectedCustomer) {
      const customerDropdownOptions = serviceHoursByCustomerByYear.data.map((customer) => customer.customerId.toString());
      const data = customersByState.dropdownOptions?.filter((option) => {
        return customerDropdownOptions.includes(option.value);
      });
      setCustomerOptions(data);

      if (data && data.length > 0) {
        const firstCustomer = data[0];
        setSelectedCustomer(firstCustomer);
        setGraphOptions(null);
        // Fetch service hours for the default customer
        const filteredData = serviceHoursByCustomerByYear?.data.filter((item) => item.customerId == firstCustomer.value);
        setGraphOptions(filteredData[0].serviceHours);
      }



    }
  }, [commonLoading, statesWithServiceTickets, customersLoading, indianStateDropdownOptions, customersByState.dropdownOptions]);

  const onIndianStateChange = (event, newValue) => {
    setSelectedIndianState(newValue);
    setGraphOptions(null);
    setSelectedCustomer(null);
    dispatch(readCustomersByState({ indianStateCode: newValue.value }));
    dispatch(getTotalServiceHoursForCustomerInStateByYear({ state: newValue.value }));
  };
  const onCustomerChange = (event, newValue) => {
    if (newValue) {
      setSelectedCustomer(newValue); // Set the new selected customer
      setGraphOptions(null);
    }

    if (serviceHoursByCustomerByYear.data) {
      const filteredData = serviceHoursByCustomerByYear?.data.filter((item) => item.customerId == newValue.value);
      setGraphOptions(filteredData[0].serviceHours);
    }
  };

  return serviceHoursByCustomerByYear.loading && commonLoading && customersLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Box>
      <Paper elevation={8} sx={{ padding: 1 }}>
        <Stack alignItems="center">
          <Typography variant="body1" align="center" sx={{ mb: 2, mt: 2 }}>
            Service Hours Last 5 Years By State For Customer
          </Typography>
          <Autocomplete
            disablePortal
            id="SelectStateServiceHoursBy4YearsByStateForCustomerBar"
            options={indianStates}
            onChange={onIndianStateChange}
            value={selectedIndianState}
            sx={{ width: 350, ml: 2, paddingBottom: 1 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
          <br />
          <Autocomplete
            disablePortal
            id="SelectCustomerServiceHoursBy4YearsByStateForCustomerBar"
            options={customerOptions}
            onChange={onCustomerChange}
            value={selectedCustomer ? selectedCustomer : null}
            sx={{ width: 350, ml: 2 }}
            renderInput={(params) => <TextField {...params} label="Customer" />}
          />
        </Stack>
        <ComposedChart
          width={380}
          height={280}
          data={graphOptions}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <Legend content={() => null} />
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey={"year"} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="hours_spent" barSize={30} fill="#bd7ebe" />
        </ComposedChart>
      </Paper>
    </Box>
  );
};

export default ServiceHoursBy4YearsByStateForCustomerBar;
