import {
  Box,
  Typography,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  Card,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  readServiceTicket,
  updateServiceTicket,
  readServiceTicketMessagesByServiceTicketId,
  addTextMessageToServiceTicket,
  requestPaidTicketInvoiceFromAccounts,
} from "../../../features/servicetickets/actions";
import { readAsyncStorageValues } from "../../../features/common/actions";
import { getCustomersWithServiceTickets, readServiceTimeLog } from "../../../features/servicetickets/actions";
import { readCustomerById, readMachinesByCustomerId } from "../../../features/customers/actions";
import { getAppUsers } from "../../../features/users/actions";
import Timelogs from "../../Timelog/Timelogs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Gallery from "../../Gallery";
const ViewServiceTicket = () => {
  const dispatch = useDispatch();

  const { serviceTicketById, loading, serviceTicketMessages } = useSelector((state) => state.serviceTickets);
  const { customerDataById, machinesByCustomerId } = useSelector((state) => state.customers);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { appUsersList } = useSelector((state) => state.users);
  const { userCurrentRole } = useSelector((state) => state.auth);
  const [showDialog, setShowDialog] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { serviceTicketId } = useParams();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [salesUsersList, setSalesUsersList] = useState([]);
  const [termsOfServiceList, setTermsOfServiceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [serviceEngineerList, setServiceEngineerList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [addDescription, setAddDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editFormData, setEditFormData] = useState({
    status: "",
    serviceTicketId: "",
    machineId: "",
    salesPersonId: "",
    ticketCategory: "",
    customerId: "",
    assignees: [],
    otherUsers: [],
    scheduledDate: null,
    salesPerson: null,
    serviceTicketPaymentType: "",
    description: "",
  });

  useEffect(() => {
    dispatch(readServiceTicket(serviceTicketId));
    if (state?.editMode) setEditMode(true);
  }, [dispatch, serviceTicketId]);

  useEffect(() => {
    if (serviceTicketById?.customer?.id) dispatch(readMachinesByCustomerId(serviceTicketById.customer.id));
    setFilteredMachines([]);
  }, [dispatch, serviceTicketById]);
  useEffect(() => {
    if (machinesByCustomerId.data) {
      const formattedMachines =
        machinesByCustomerId.data.map((machine) => ({
          ...machine,
          label: formatMachineLabel(machine),
        })) || [];

      const machineInfo = formattedMachines.find((item) => item.id === serviceTicketById?.machine?.id);
      if (machineInfo) {
        setEditFormData({
          ...editFormData,
          machineId: machineInfo,
        });
      }
      setFilteredMachines(formattedMachines);
    } else {
      setFilteredMachines([]);
    }
  }, [machinesByCustomerId]);

  useEffect(() => {
    if (asyncStorageValues?.allTypes) {
      const { SERVICE_CATEGORY, SERVICE_TICKET_PAYMENT_TYPE, SERVICE_TICKET_STATUS } = asyncStorageValues?.allTypes;
      const categories = Object.values(SERVICE_CATEGORY);
      setServiceCategories(categories);
      const termsOfService = Object.values(SERVICE_TICKET_PAYMENT_TYPE);
      setTermsOfServiceList(termsOfService);
      const status = Object.values(SERVICE_TICKET_STATUS);
      setStatusList(status);
    }
  }, [asyncStorageValues]);
  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
    dispatch(getAppUsers());
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  const formatMachineLabel = (machine) => {
    const { manufacturer, model, serialNumber, amcExpiryDate, warrantyExpiryDate } = machine;
    let serviceStatus;

    if (warrantyExpiryDate && new Date(warrantyExpiryDate) > new Date()) {
      serviceStatus = `Warranty ${moment(warrantyExpiryDate).format("DD MMM YY")}`;
    } else if (amcExpiryDate && new Date(amcExpiryDate) > new Date()) {
      serviceStatus = `AMC ${moment(amcExpiryDate).format("DD MMM YY")}`;
    } else {
      serviceStatus = "PAID SERVICE";
    }

    return `${manufacturer}, ${model} [${serialNumber}, ${serviceStatus}]`;
  };
  const salesPersonName = asyncStorageValues?.appUsers?.find((user) => user.userId === serviceTicketById?.salesPerson?.id)?.name || "";
  const machine = machinesByCustomerId?.data?.find((machine) => machine.id === serviceTicketById?.machine?.id);
  const serviceStatus = (() => {
    if (machine) {
      const { warrantyExpiryDate, amcExpiryDate } = machine;
      if (warrantyExpiryDate && new Date(warrantyExpiryDate) > new Date()) {
        return `Warranty ${moment(warrantyExpiryDate).format("DD MMM YY")}`;
      } else if (amcExpiryDate && new Date(amcExpiryDate) > new Date()) {
        return `AMC ${moment(amcExpiryDate).format("DD MMM YY")}`;
      } else {
        return "PAID SERVICE";
      }
    }
  })();
  const model = machine ? `${machine.manufacturer}, ${machine.model} [${machine.serialNumber}, ${serviceStatus}]` : "";

  useEffect(() => {
    if (asyncStorageValues?.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const salesUsers = appUsersList.data
        .filter((obj) => (obj.userRole === roles.ROLE_REGIONAL_SALES_MANAGER || obj.userRole === roles.ROLE_SALES_PERSON) && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));
      console.log(salesUsers, Object.keys(asyncStorageValues));
      // if (userCurrentRole === roles.ROLE_REGIONAL_SALES_MANAGER) {
      //   setSalesUsersList(salesUsers?.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      // } else {
      setSalesUsersList(salesUsers || []);
      // }

      const serviceUsers = appUsersList.data
        .filter((obj) => obj.userRole === roles.ROLE_SERVICE_ENGINEER || (obj.userRole === roles.ROLE_SERVICE_COORDINATOR && obj.enabled))
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_SERVICE_ENGINEER) {
        setServiceEngineerList(serviceUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setServiceEngineerList(serviceUsers);
      }
    }
  }, [appUsersList, asyncStorageValues, userCurrentRole]);

  useEffect(() => {
    if (serviceTicketById && appUsersList.data) {
      if (serviceTicketById?.additionalNotes) {
        let additionalNotesObject = JSON.parse(serviceTicketById.additionalNotes);
        setAdditionalNotes(additionalNotesObject);
      }
      if (serviceTicketById?.customer?.id) {
        const customerId = serviceTicketById.customer.id;
        dispatch(readCustomerById(customerId));
      }
      dispatch(readServiceTicketMessagesByServiceTicketId(serviceTicketId));
      setEditFormData({
        ...editFormData,
        status: serviceTicketById.status,
        serviceTicketId: serviceTicketById.id,
        salesPersonId: serviceTicketById.salesPerson ? serviceTicketById.salesPerson.id : "",
        ticketCategory: serviceTicketById.ticketCategory,
        customerId: serviceTicketById.customer ? serviceTicketById.customer.id : "",
        assignees: serviceTicketById.assignees ? serviceTicketById.assignees.map((assignee) => assignee.id) : [],
        otherUsers: serviceTicketById.otherUsers ? serviceTicketById.otherUsers.map((user) => user.id) : [],
        scheduledDate: serviceTicketById.scheduledDate ? moment(serviceTicketById.scheduledDate) : null,
        serviceTicketPaymentType: serviceTicketById.serviceTicketPaymentType,
        description: serviceTicketById.description,
        // serviceEngineer: serviceTicketById?.assignees?.map((assignee) => ({ ...assignee, userId: assignee.id })),
        serviceEngineer: appUsersList?.data?.filter((user) =>
          serviceTicketById?.assignees?.some((assignee) => assignee.id === user.userId)
        ),
        salesPerson: appUsersList?.data?.find((user) => serviceTicketById?.salesPerson?.id === user.userId),
      });
    }
  }, [serviceTicketById, appUsersList]);

  const customStyle = {
    p: 2,
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
    mb: 1,
  };

  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 50000) {
      setNewNote(inputValue);
      setErrorMsg("");
    } else {
      setErrorMsg(`Character limit reached (50000 characters max)`);
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
    setNewNote("");
  };
  const handleAddNote = async () => {
    if (newNote.length && newNote.trim()) {
      const postData = {
        serviceTicketId,
        message: newNote,
      };
      dispatch(addTextMessageToServiceTicket(postData)).then((res) =>
        dispatch(readServiceTicketMessagesByServiceTicketId(serviceTicketId))
      );
      setNewNote("");
      setShowDialog(false);
    } else setErrorMsg("*Please enter notes.");
  };

  const handleDesciptionChange = (event) => {
    const submitValue = event.target.value;
    if (submitValue.length <= 400) {
      setAddDescription(submitValue);
      setErrorMsg("");
    } else {
      setErrorMsg(`Character limit reached (400 characters max)`);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAddDescription("");
  };

  const handleDescriptionSubmit = async () => {
    if (addDescription.length && addDescription.trim()) {
      const submitData = {
        serviceTicketId: serviceTicketById.id,
        emailMessage: addDescription,
      };
      setIsSubmitting(true);
      const res = await dispatch(requestPaidTicketInvoiceFromAccounts(submitData));
      if (serviceTicketById?.id) dispatch(readServiceTicket(serviceTicketById?.id));
      if (res && res.payload && res.payload.status === 200) {
        toast.success("Submitted successfully!", { autoClose: 1000 });
      } else toast.error("Something went wrong!", { autoClose: 1000 });
      setAddDescription("");
      setOpenDialog(false);
    } else setErrorMsg("*Please enter a description.");
  };

  const minutes_to_hhmm = (numberOfMinutes) => {
    var hh =
      numberOfMinutes.years() * (365 * 24) + numberOfMinutes.months() * (30 * 24) + numberOfMinutes.days() * 24 + numberOfMinutes.hours();
    var mm = numberOfMinutes.minutes();
    return hh + "h " + mm + " m";
  };
  const isDataLoaded = serviceTicketById && asyncStorageValues;
  const filteredNotes = serviceTicketMessages.data?.filter((message) => message.type === "text").reverse();
  const handleEditSave = () => {
    if (
      !editFormData.status ||
      !editFormData.customerId ||
      !editFormData.description ||
      !editFormData.salesPerson ||
      !editFormData.ticketCategory
    )
      return toast.warn("Please fill all required fields!");
    const updatedData = {
      status: editFormData.status,
      serviceTicketId: editFormData.serviceTicketId,
      machineId: editFormData.machineId.id,
      salesPersonId: editFormData?.salesPerson?.userId || null,
      ticketCategory: editFormData.ticketCategory,
      customerId: editFormData.customerId,
      assignees: editFormData.serviceEngineer?.map((engr) => engr.userId) || [],
      otherUsers: editFormData.otherUsers,
      scheduledDate: editFormData.scheduledDate ? moment(editFormData.scheduledDate).toISOString() : null,
      serviceTicketPaymentType: editFormData.serviceTicketPaymentType,
      description: editFormData.description,
    };
    // console.log("---", updatedData);
    dispatch(updateServiceTicket(updatedData))
      .then(() => {
        setEditMode(false);
        toast.success(`Servicet ${serviceTicketById.id} updated succesfully!`);
        return dispatch(readServiceTicket(serviceTicketId));
      })
      .catch((error) => {
        console.error("Error updating service ticket:", error);
      });
  };

  return (
    <div>
      <Box sx={{ mx: 3, marginY: 2, marginLeft: "3%" }}>
        {isDataLoaded ? (
          <>
            <Paper sx={{ borderRadius: 2, mb: 1.5, p: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}>
              <Stack flexDirection="row" justifyContent={"space-between"} sx={{ flex: 1, flexWrap: "wrap" }}>
                {editMode ? (
                  <>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1, marginRight: 1 }}>
                      <TextField label="Customer Name" name="customerName" value={customerDataById?.name || ""} disabled fullWidth />
                      <Autocomplete
                        options={serviceCategories}
                        getOptionLabel={(option) => option}
                        value={editFormData.ticketCategory}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            ticketCategory: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Select Category" required />}
                      />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Scheduled Date"
                          value={editFormData.scheduledDate}
                          format={"DD MMM YYYY"}
                          onChange={(newValue) => {
                            setEditFormData({ ...editFormData, scheduledDate: newValue });
                          }}
                          minDate={moment()}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                      <Autocomplete
                        multiple
                        options={serviceEngineerList}
                        getOptionLabel={(option) => option.name}
                        value={editFormData.serviceEngineer}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            serviceEngineer: newValue,
                            status: asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Assigned to" required />}
                        isOptionEqualToValue={(option, value) => option.userId === value?.userId}
                      />
                      <TextField
                        label="Description"
                        name="description"
                        value={editFormData.description || ""}
                        onChange={(e) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            description: e.target.value,
                          }))
                        }
                        fullWidth
                      />
                    </Stack>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1 }}>
                      <Autocomplete
                        options={statusList}
                        getOptionLabel={(option) => option}
                        value={editFormData?.status || serviceTicketById?.status}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            status: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Status" required />}
                      />
                      <Autocomplete
                        options={salesUsersList}
                        getOptionLabel={(option) => option.name}
                        value={editFormData.salesPerson || null}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            salesPerson: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Sales Person" required />}
                        isOptionEqualToValue={(option, value) => option.userId === value.userId}
                      />
                      <Autocomplete
                        options={filteredMachines}
                        getOptionLabel={(option) => option.label}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={editFormData.machineId || null}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            machineId: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Machine" required />}
                      />

                      <Autocomplete
                        options={termsOfServiceList}
                        getOptionLabel={(option) => option}
                        value={editFormData.serviceTicketPaymentType}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            serviceTicketPaymentType: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Terms Of Service" required />}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Button
                        disabled={loading}
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{ height: "fit-content", marginLeft: 0.8, textTransform: "capitalize" }}
                        onClick={() => handleEditSave()}
                      >
                        {loading ? "saving.." : "Save"}
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{ height: "fit-content", textTransform: "capitalize" }}
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1 }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                        Service Ticket : {serviceTicketById.id}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Customer Name : </span>
                        {customerDataById?.name || ""},{customerDataById?.state || ""}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Ticket Category : </span>
                        {serviceTicketById?.ticketCategory}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Scheduled Date : </span>
                        {serviceTicketById?.scheduledDate ? moment(serviceTicketById.scheduledDate).format("DD MMM YYYY") : "-"}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Assigned to : </span>
                        {editFormData?.serviceEngineer?.map((item) => item.name).join(", ") || "-"}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Description : </span>
                        {serviceTicketById.description}
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1, marginTop: "3%" }}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Status : </span>
                        {serviceTicketById.status}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Sales Person : </span>
                        {salesPersonName}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Model : </span>
                        {model}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Terms of Service : </span>
                        {serviceTicketById.serviceTicketPaymentType}
                      </Typography>
                    </Stack>
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "flex-start" }}>
                      {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER &&
                        asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => setEditMode(true)}
                            sx={{ width: "8rem", height: "fit-content", textTransform: "capitalize" }}
                          >
                            Edit
                          </Button>
                        )}
                      {additionalNotes?.serviceTicketReportPdf ? (
                        <Button
                          sx={{ width: "8rem", height: "2rem", textTransform: "capitalize" }}
                          variant="contained"
                          onClick={() => {
                            navigate("/view-report/" + serviceTicketById.id);
                          }}
                        >
                          View report
                        </Button>
                      ) : null}
                      <>
                        {(serviceTicketById.status ===
                          asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS?.REQUEST_INVOICE_SPARES_REQUIRED ||
                          serviceTicketById.status ===
                            asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS?.REQUEST_INVOICE_SPARES_RECOMMENDED ||
                          serviceTicketById.status === asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS?.REQUEST_INVOICE) &&
                          userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_COORDINATOR && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => setOpenDialog(true)}
                              sx={{ width: "8rem", height: "fit-content", textTransform: "capitalize" }}
                            >
                              Request Invoice
                            </Button>
                          )}
                        <Dialog
                          open={openDialog}
                          onClose={handleClose}
                          alignSelf="center"
                          maxWidth="md"
                          fullWidth
                          sx={{
                            "& .MuiDialog-paper": {
                              width: "650px",
                              maxWidth: "650px",
                              marginLeft: "10%",
                            },
                          }}
                        >
                          <DialogTitle>Request Invoice</DialogTitle>
                          <Typography sx={{ ml: 3 }}>{customerDataById?.name || ""}</Typography>
                          <DialogContent>
                            <Stack spacing={2} sx={{ mt: 2 }}>
                              <TextField
                                value={addDescription}
                                label="Description"
                                multiline
                                fullWidth
                                onChange={handleDesciptionChange}
                                helperText={errorMsg || `${addDescription.length}/400`}
                              />
                            </Stack>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose} color="primary">
                              Cancel
                            </Button>
                            <Button
                              sx={{ height: "self", textTransform: "capitalize" }}
                              variant="contained"
                              onClick={handleDescriptionSubmit}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    </div>
                  </>
                )}
              </Stack>
            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={10} md={4}>
                <Paper
                  sx={{ borderRadius: 2, mb: 0.8, px: 2, py: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}
                >
                  <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" sx={{ mb: 0.8 }} fontWeight={600}>
                      Notes
                    </Typography>
                    <Tooltip title="Add note">
                      <Button
                        sx={{ height: "self", textTransform: "capitalize" }}
                        variant="contained"
                        color="primary"
                        onClick={() => setShowDialog(true)}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Stack>
                  <Dialog open={showDialog} onClose={handleCancel}>
                    <DialogTitle>Add note</DialogTitle>
                    <DialogContent>
                      <TextField
                        value={newNote}
                        label="Note"
                        multiline
                        onChange={handleNoteChange}
                        error={errorMsg && !newNote.length}
                        helperText={errorMsg || `${newNote.length}/50000`}
                        sx={{ minWidth: 350, m: 2, maxWidth: 900, maxHeight: 700 }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button sx={{ height: "self", textTransform: "capitalize" }} variant="contained" onClick={handleAddNote}>
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <List>
                    {filteredNotes?.map((note, index) => (
                      <React.Fragment key={index}>
                        {note.type === asyncStorageValues?.allTypes?.TYPE_OF_CONTENT?.TEXT ? (
                          <Paper>
                            <Stack minWidth={120} justifyContent={"space-between"} sx={customStyle}>
                              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2" fontWeight={400}>
                                  {note?.postedDate || note?.dateCreated
                                    ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                                    : "N.A"}{" "}
                                </Typography>
                                <Typography sx={{ display: "inline" }} variant="body2">
                                  {note.postedUser.name || "-"}
                                </Typography>
                              </Stack>
                              <Typography sx={{ display: "inline" }}>{note.message}</Typography>
                            </Stack>
                          </Paper>
                        ) : null}
                        {index < filteredNotes.length - 1 && <Divider component="li" />}
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={10} md={4}>
                <Timelogs ticketId={serviceTicketId} />
              </Grid>
              <Grid item xs={10} md={4}>
                <Paper
                  sx={{ borderRadius: 2, mb: 0.8, px: 2, py: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}
                >
                  <Gallery ticketId={serviceTicketId} />
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </div>
  );
};

export default ViewServiceTicket;
