import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Box,
  Card,
  TextField,
  Tooltip,
  CardContent,
  Autocomplete,
  IconButton,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Upload as UploadIcon,
  AttachFile as AttachFileIcon,
  DragIndicator as DragIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";
import { MyPointerSensor } from "../../../utils/UtilClasses/DNDSensors";

import { createTemplate, updateTemplate, getAllTemplates } from "../../../features/templates/actions";

import DragAndDrop from "../../../components/DragAndDrop";
import Cookies from "universal-cookie";
// import { translateText } from "utils/utilFunctions";
import AuthorizedImage from "../../../components/AuthorizedImage";
import DrawingComponent from "../../../components/DrawingComponent";
import ViewImageVideoModal from "../../../components/ViewImageVideoModal";

const AddEditNewTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [textSelectionStartIndex, setTextSelectionStartIndex] = useState(null);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [templateImages, setTemplateImages] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isDrawingComponentOpen, setDrawingComponentOpen] = useState(false);
  const [selectedImageToEdit, setSelectedImageToEdit] = useState(null);
  const [selectedTaskSection, setSelectedTaskSection] = useState(null);
  const [showMultipleImageAlert, setShowMultipleImageAlert] = useState(false);
  const [dragOrCopyLinkToModal, setDragOrCopyLinkToModal] = useState(false);
  const [showDialogView, setShowDialogView] = useState(false);
  const [taskImageList, setTaskImageList] = useState([]);
  const [reorderItems, setReorderItems] = useState(false);

  const lastRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const { asyncStorageValues } = useSelector((state) => state.common);
  const { allTemplates } = useSelector((state) => state.templates);
  const { state } = useLocation();
  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  const [isEditing, setIsEditing] = useState(false);

  const [templateInfo, setTemplateInfo] = useState({
    name: "",
    periodicity: null,
    active: true,
  });
  const [sections, setSections] = useState([]);
  const [tasksObject, setTasksObject] = useState({});

  const setInitialData = async (templateData = {}) => {
    const cookies = new Cookies();
    const currentCookie = cookies.get("googtrans");
    const initialState = { ...templateData };
    const templateFormObj = { ...templateData };
    // const currrentLang = currentCookie?.split("/")[2];
    // if (currrentLang !== "en") {
    //   const templateSections = await Promise.all(
    //     initialState?.sections.map(async (section, index) => {
    //       const updatedTasks = await Promise.all(
    //         section.tasks.map(async (taskitem) => {
    //           const translatetask = await translateText(taskitem?.task || "", currrentLang);
    //           return {
    //             ...taskitem,
    //             task: translatetask[0]?.translatedText || "N.A",
    //           };
    //         })
    //       );
    //       const sectionName = await translateText(section?.name || "", currrentLang);
    //       return {
    //         ...section,
    //         name: sectionName[0]?.translatedText || "N.A",
    //         tasks: updatedTasks,
    //         index,
    //       };
    //     })
    //   );

    //   const templateName = initialState.name ? await translateText(initialState?.name || "", currrentLang) : null;
    //   templateFormObj.templateId = initialState.id;
    //   templateFormObj.name = templateName ? templateName[0]?.translatedText : initialState.name;
    //   templateFormObj.sections = templateSections;
    // }
    const sections = [...templateFormObj?.sections] || [];
    const templateSections = sections?.map((section) => ({ ...section }));
    const templateSectionsTasks = sections?.reduce((acc, current) => {
      acc[current.id] = current.tasks;
      return acc;
    }, {});
    const clonedObject = structuredClone(templateSectionsTasks);
    setTemplateInfo({
      periodicity: templateData?.periodicity || null,
      active: templateData.active,
      name: templateFormObj.name,
    });
    setSections(templateSections);
    setTasksObject(clonedObject);
  };
  useEffect(() => {
    let templateBasicInfo = {
      name: "",
    };
    // if (asyncStorageValues && asyncStorageValues?.machineModels) {
    //   const modifiedList = asyncStorageValues.machineModels
    //     .map((item) => ({
    //       ...item,
    //       modelManufacturer: `${item.model} [${item.manufacturer}]`,
    //     }))
    //     .sort((a, b) => a.modelManufacturer.localeCompare(b.modelManufacturer));
    //   if (id && allTemplates.data.length) {
    //     const template = allTemplates.data.find((item) => item.id == id);
    //     const modelInfo = modifiedList.find((item) => item.id == template?.modelId);
    //     if (modelInfo) templateBasicInfo.machineModel = modelInfo;
    //   }

    //   setMachineModelsList(modifiedList || []);
    // }
    if ((state?.copyTemplateId || id) && allTemplates.data.length) {
      const template = allTemplates.data.find((item) => item.id == id || item.id == state?.copyTemplateId);
      templateBasicInfo = template ? { ...templateBasicInfo, ...template } : {};

      if (state?.copyTemplateId) {
        templateBasicInfo.name = "";
        templateBasicInfo.id = undefined;
      }
      const templateBasic = { ...templateInfo, ...templateBasicInfo };
      setInitialData(templateBasic);
    }
  }, [asyncStorageValues, allTemplates.data, id, state?.copyTemplateId]);

  const TaskImagesComponent = ({ taskItem, sectionItem, templateTaskImages = [] }) => {
    const taskImageItem = templateTaskImages.find(
      (imageItem) => imageItem.taskId === taskItem.id && imageItem.sectionId === sectionItem.id
    );
    return (
      <>
        {taskImageItem && taskImageItem?.images && (
          <Stack mt={2} direction={"row"} gap={2}>
            {taskImageItem?.images.map((imageUri) => (
              <div>
                <img src={imageUri.fileUri} width={100} height={100} />
              </div>
            ))}
          </Stack>
        )}
      </>
    );
  };

  const handleCloseDialogView = () => {
    // setCurrentFilePathIndex(null);
    setTaskImageList([]);
    setShowDialogView(false);
    // fileInputRef.current.value = null;
  };
  const cancelSelection = () => {
    setDrawingComponentOpen(false);
    setSelectedImageToEdit(null);
    setSelectedTaskSection(null);
  };
  const onClickDoneEditing = async (editedImage) => {
    setDrawingComponentOpen(false);
    setSelectedImageToEdit(null);
    if (editedImage) {
      const { taskItem, sectionItem } = selectedTaskSection;
      const newAttachments = [{ fileItem: editedImage, fileUri: URL.createObjectURL(editedImage) }];
      const updateImageIndex = templateImages.findIndex(
        (imageItem) => imageItem.taskId === taskItem.id && imageItem.sectionId === sectionItem.id
      );
      let imagesToUpload = [];
      if (updateImageIndex == -1) {
        imagesToUpload = [
          ...templateImages,
          {
            taskId: taskItem.id,
            sectionId: sectionItem.id,
            images: newAttachments,
          },
        ];
        setTemplateImages(imagesToUpload);
      } else {
        imagesToUpload = [...newAttachments];
        templateImages[updateImageIndex].images = imagesToUpload;
        setTemplateImages(imagesToUpload);
      }
      const templateTaskIndex = tasksObject[sectionItem.id].findIndex((task) => task.id === taskItem.id);
      tasksObject[sectionItem.id][templateTaskIndex].images = [];
      setTasksObject({ ...tasksObject });
      validate(sections, imagesToUpload);
    }
    setSelectedTaskSection(null);
  };

  const validate = async (sectionsToUpdate = sections, imagesToUpload = templateImages) => {
    let errMessage = "Please enter ";
    if (!templateInfo.name.trim()) {
      setErrorMsg(errMessage);
      return toast.warn("Please enter name!");
    }
    if (id) {
      const isTemplateNameUsed = allTemplates.data.filter(
        (item) => item.name.toLowerCase() == templateInfo.name.toLowerCase() && item.id != id
      );
      if (isTemplateNameUsed.length) {
        setErrorMsg("Please enter a new ");
        return toast.warn("Template name exists!");
      }
    }
    const validateSections = sectionsToUpdate.filter((item) => !item.name.trim());
    if (validateSections.length) {
      setErrorMsg(errMessage);
      return toast.warn("Sections are empty!");
    }
    let isTaksEmpty = false;
    Object.keys(tasksObject).forEach((key) => {
      const emptyTasks = tasksObject[key].filter((item) => !item?.task || !item.task.trim());
      if (emptyTasks.length) isTaksEmpty = true;
    });
    if (isTaksEmpty) {
      setErrorMsg(errMessage);
      return toast.warn("Tasks are empty!");
    }
    const templateSections = await Promise.all(
      sectionsToUpdate.map(async (section, index) => {
        // const sectionName = await translateText(section?.name || "");
        if (tasksObject[section.id]) {
          const updatedTasks = await Promise.all(
            tasksObject[section.id].map(async (taskitem, taskIndex) => {
              //   const translatetask = await translateText(taskitem?.task || "");
              return {
                ...taskitem,
                hasRemarks: true,
                task: taskitem?.task, //  translatetask[0]?.translatedText || "N.A",
                index: taskIndex,
              };
            })
          );
          return {
            ...section,
            name: section?.name || "", //sectionName[0]?.translatedText || "N.A",
            tasks: updatedTasks,
            index,
          };
        } else
          return {
            ...section,
            name: section?.name || "", //sectionName[0]?.translatedText || "N.A",
            tasks: [],
            index,
          };
      })
    );
    const templateName = templateInfo?.name; //await translateText(templateInfo?.name || "");
    const orderObject = {
      ...templateInfo,
      name: templateName[0]?.translatedText || templateInfo.name,
      periodicity: templateInfo?.periodicity || asyncStorageValues?.periodicity.none,
      sections: templateSections,
    };
    if (id) {
      orderObject.templateId = parseInt(id);
      submitTemplateForm(updateTemplate, orderObject, imagesToUpload);
    } else submitTemplateForm(createTemplate, orderObject, imagesToUpload);
  };
  const submitTemplateForm = (action, formData, imagesToUpload = templateImages) => {
    const formDataToPost = new FormData();
    formDataToPost.append("template", JSON.stringify(formData));
    const imageFilesMap = [];
    const imageFiles = [];

    for (let i = 0; i < imagesToUpload.length; i++) {
      const imagesList = imagesToUpload[i].images.map((imageItem) => imageItem.fileItem);
      imageFiles.push(...imagesList);
      imageFilesMap.push({
        taskId: imagesToUpload[i].taskId,
        sectionId: imagesToUpload[i].sectionId,
        fileNames: imagesList.map((imageItem) => imageItem.name),
      });
    }
    for (let image = 0; image < imageFiles.length; image++) {
      formDataToPost.append("templateTaskImages", imageFiles[image]);
    }
    formDataToPost.append("templateTaskImageMap", JSON.stringify(imageFilesMap));
    setTemplateImages([]);
    dispatch(action(formDataToPost)).then((templateResp) => {
      setReorderItems(false);
      if (templateResp && templateResp.error) return toast.error("Something went wrong!");
      if (templateResp && templateResp.payload.data && templateResp.payload.status === 200) {
        if (templateResp.payload.data.status === "409") return toast.warn(templateResp.payload.data.message);
        if (templateResp.payload.data.status === "404") return toast.warn(templateResp.payload.data.message);
        dispatch(getAllTemplates()).then((res) => {
          toast.success("Template " + (id ? "updated" : "added") + " successfully!", {
            autoClose: 1000,
          });
          if (!id) navigate(`/edit-template/` + templateResp.payload.data.id, { replace: true });
        });
      } else return toast.error("Something went wrong!");
    });
  };
  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setDragOrCopyLinkToModal(false);
    setShowMultipleImageAlert(false);
    setDrawingComponentOpen(true);
    setSelectedImageToEdit(filesArray[0]);
  };
  const arrayMove = (array, oldIndex, newIndex) => {
    return dndKitArrayMove(array, oldIndex, newIndex);
  };
  const sensors = useSensors(
    useSensor(MyPointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSectionDragEnd = (active, over) => {
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current?.sortable.index || 0;
      let newItems;
      if (activeContainer === overContainer) newItems = arrayMove(sections, activeIndex, overIndex);
      setSections(newItems);
    }
  };
  const handleDragOver = ({ over }) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }
    const overContainer = over.data.current?.sortable.containerId;

    if (!overContainer) {
      return;
    }
  };

  const handleDragEnd = (active, over, sectionItem) => {
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current?.sortable.index || 0;
      let newItems;
      if (activeContainer === overContainer) newItems = arrayMove(tasksObject[sectionItem.id], activeIndex, overIndex);
      tasksObject[sectionItem.id] = newItems;
      setTasksObject({ ...tasksObject });
    }
  };
  const ItemComponent = (taskItem, taskIndex, sectionItem) => {
    return (
      <div id={sectionItem.id + taskItem.id + ""} key={sectionItem.id + taskItem.id + ""}>
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Typography mr={1}>{`${taskIndex + 1}. `}</Typography>
          {!templateImages.length && taskItem?.images && (
            <Stack mr={1} mt={1}>
              {[...taskItem?.images].map((imageItem, index) => (
                <div key={imageItem + index}>
                  <AuthorizedImage path={imageItem} width={100} height={100} />
                </div>
              ))}
            </Stack>
          )}
          <TaskImagesComponent taskItem={taskItem} sectionItem={sectionItem} templateTaskImages={templateImages} />
          <Typography>{` ${taskItem?.task || ""}`}</Typography>
        </Stack>
      </div>
    );
  };
  const sectionComponent = (sectionItem, sectionIndex) => (
    <div sx={{ p: 2, mt: 1 }} ref={sectionIndex === sections.length - 1 ? lastRef : undefined}>
      <Stack direction={"row"} columnGap={2} alignItems={"center"} mt={2}>
        <Typography>{`${sectionIndex + 1}.  ${sectionItem?.name || ""}`}</Typography>
      </Stack>
      <Stack>
        <div>
          <DndContext
            sensors={sensors}
            onDragEnd={({ active, over }) => handleDragEnd(active, over, sectionItem)}
            onDragOver={handleDragOver}
          >
            <div style={{}}>
              <DragAndDrop
                nestedItem={true}
                isEditing={isEditing}
                id={sectionIndex + ""}
                items={tasksObject[sectionItem.id] || []}
                key={sectionIndex + ""}
                itemComponent={({ item, index }) => ItemComponent(item, index, sectionItem)}
              />
            </div>
          </DndContext>
        </div>

        {tasksObject[sectionItem.id].length > 2 ? (
          <Stack justifyContent={"flex-end"} direction={"row"} columnGap={2} alignItems={"center"} mt={2}>
            <Tooltip title="Add new task">
              <Button
                disabled={reorderItems}
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ height: "self", whiteSpace: "nowrap" }}
                autoCapitalize="none"
                size="medium"
                onClick={() => {
                  const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
                  tasksObject[sectionItem.id].push({
                    task: null,
                    images: [],
                    index: tasksObject[sectionItem.id].length + "",
                    id: randomNumber + sectionIndex + "",
                  });
                  setTasksObject({ ...tasksObject });
                  setErrorMsg(null);
                }}
              >
                New Task
              </Button>
            </Tooltip>
            <Tooltip title={"Remove section"}>
              <DeleteIcon
                color="error"
                onClick={() => {
                  setSelectedSectionIndex(sectionIndex);
                  setShowDialog(true);
                }}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Stack>
        ) : null}
      </Stack>
    </div>
  );
  const onPasteOrDrop = (e, clipboardData) => {
    e.stopPropagation();
    e.preventDefault();
    // Get pasted data via clipboard API
    // const clipboardData = e.clipboardData || window.clipboardData;

    if (clipboardData.files[0]) {
      const newAttachments = Array.from(clipboardData.files);
      setDragOrCopyLinkToModal(false);
      setShowMultipleImageAlert(false);
      setDrawingComponentOpen(true);
      setSelectedImageToEdit(newAttachments[0]);
      // const fileNames = newAttachments.map((item) => `<!--${item.name}-->`).join(" \n");
    }
  };
  const handleOndragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <div>
        {isDrawingComponentOpen && (
          <DrawingComponent
            isDrawingComponentOpen={isDrawingComponentOpen}
            closeDrawingComponent={setDrawingComponentOpen}
            onClickDoneEditing={onClickDoneEditing}
            selectedImageUri={selectedImageToEdit}
            cancelSelection={cancelSelection}
            buttonText={"Upload"}
          />
        )}
      </div>
      <div>
        {
          <ViewImageVideoModal
            showDialogView={showDialogView}
            handleCloseDialogView={handleCloseDialogView}
            data={taskImageList}
            selectedFileIndex={0}
          />
        }
      </div>
      <div>
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle fontWeight={"bold"}>Alert</DialogTitle>
          <DialogContent>
            <Typography>You are about to delete the whole section.</Typography>
            <Typography>Please press "Confirm Delete" to delete the selected section.</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                sections.splice(selectedSectionIndex, 1);
                validate(sections);
                setSections([...sections]);
                setShowDialog(false);
                setSelectedSectionIndex(null);
              }}
              color="primary"
            >
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={dragOrCopyLinkToModal}
          onClose={() => setDragOrCopyLinkToModal(false)}
          PaperProps={{
            style: {
              minWidth: "500px",
              marginTop: -4,
            },
          }}
        >
          <DialogTitle mt={2} ml={3} fontWeight={"bold"}>
            Upload
          </DialogTitle>
          <DialogContent sx={{ ml: 3, mr: 2 }}>
            <TextField
              multiline
              rows={6}
              fullWidth
              value={""}
              inputProps={{ maxLength: 50000, placeholder: "Drag and drop / paste image here..." }}
              onPasteCapture={(e) => onPasteOrDrop(e, e.clipboardData || window.clipboardData)}
              onDrop={(e) => onPasteOrDrop(e, e.dataTransfer)}
              onDragOver={handleOndragOver}
            />
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button sx={{ mr: 2 }} variant="contained" onClick={() => setDragOrCopyLinkToModal(false)}>
              Cancel
            </Button>
            <input
              type="file"
              accept="image/*"
              id={"attachment-input-task"}
              // id={"attachment-input-task" + selectedTaskSection.sectionItem.id + selectedTaskSection.taskItem.id}
              style={{ display: "none" }}
              onChange={(event) => {
                handleAttachmentChange(event);
              }}
            />
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => {
                document.getElementById("attachment-input-task").click();
              }}
            >
              <UploadIcon />
              <Typography>Upload from device</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={showMultipleImageAlert} onClose={() => setShowMultipleImageAlert(false)}>
          <DialogTitle mt={2} ml={2} fontWeight={"bold"}>
            Alert
          </DialogTitle>
          <DialogContent sx={{ ml: 3, mr: 2 }}>
            <Typography>
              <li>Only one image can be added to a template task.</li>
            </Typography>
            <Typography>
              <li>If you add a new image, it will replace the existing one.</li>
            </Typography>
            <Typography>
              <li>
                Please press <span style={{ fontWeight: "bold" }}>"Continue"</span> to add a new image.
              </li>
            </Typography>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button sx={{ mr: 2 }} variant="contained" onClick={() => setShowMultipleImageAlert(false)}>
              Cancel
            </Button>
            <Tooltip title={"Continue"}>
              <Button
                variant="contained"
                onClick={() => {
                  setShowMultipleImageAlert(false);
                  setDragOrCopyLinkToModal(true);
                }}
                color="primary"
              >
                Continue
              </Button>
              {/* </label> */}
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>
      <Box sx={{ mx: 2, minWidth: 370 }}>
        <Card sx={{ p: 2 }}>
          <div>
            <Stack direction={"row"} justifyContent={"space-between"} columnGap={2}>
              <div>
                <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"} rowGap={2} columnGap={3}>
                  <TextField
                    disabled={reorderItems}
                    label="Template name"
                    value={templateInfo.name || ""}
                    onChange={(e) => setTemplateInfo({ ...templateInfo, name: e.target.value })}
                    error={errorMsg && !templateInfo.name}
                    helperText={`${errorMsg && !templateInfo.name ? errorMsg + "name" : ""}`}
                    sx={{ minWidth: 500 }}
                    multiline
                    size="small"
                  />
                  <Autocomplete
                    disablePortal
                    disabled={reorderItems}
                    id="combo-box-demo"
                    onChange={(event, newValue, reason) => {
                      if (reason == "clear") setTemplateInfo({ ...templateInfo, periodicity: asyncStorageValues?.periodicity.none });
                      else setTemplateInfo({ ...templateInfo, periodicity: newValue });
                    }}
                    value={templateInfo.periodicity}
                    options={Object.values(asyncStorageValues?.periodicity) || []}
                    sx={{ minWidth: 150 }}
                    renderInput={(params) => <TextField {...params} label="Periodicity" />}
                    size="small"
                  />
                </Stack>
              </div>
              <Button variant="contained" size="small" sx={{ height: 36 }} onClick={() => validate()}>
                Save
              </Button>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={2}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  disabled={reorderItems}
                  checked={templateInfo?.active ? true : false}
                  onChange={(event) => {
                    setTemplateInfo({ ...templateInfo, active: event.target.checked });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {templateInfo?.active ? (
                  <Typography>Enabled (Uncheck the box to disable the template)</Typography>
                ) : (
                  <Typography>Disabled (check the box to enable the template)</Typography>
                )}
              </Stack>
              <Stack gap={2} direction={"row"}>
                <Tooltip title={"Add section"}>
                  <Button
                    disabled={reorderItems}
                    startIcon={<AddIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => {
                      const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
                      setSections([
                        ...sections,
                        {
                          name: "",
                          id: randomNumber + "",
                          index: sections.length + "",
                        },
                      ]);
                      setTasksObject({ ...tasksObject, [randomNumber + ""]: [] });
                      lastRef.current.scrollIntoView();
                    }}
                  >
                    New section
                  </Button>
                </Tooltip>
                <Tooltip title={"Change order of items"}>
                  <Button
                    disabled={reorderItems}
                    startIcon={<DragIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => setReorderItems(true)}
                  >
                    {reorderItems ? "Click save" : "Reorder Items"}
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </div>
        </Card>
        {reorderItems ? (
          <div style={{ marginTop: 5 }}>
            <DndContext
              sensors={sensors}
              onDragEnd={({ active, over }) => handleSectionDragEnd(active, over)}
              // onDragOver={handleDragOver}
            >
              <div>
                <DragAndDrop
                  nestedItem={false}
                  isEditing={isEditing}
                  id={templateInfo.name + ""}
                  items={sections || []}
                  key={templateInfo.name + ""}
                  itemComponent={({ item, index }) => sectionComponent(item, index)}
                />
              </div>
            </DndContext>
          </div>
        ) : (
          <div>
            {sections.map((sectionItem, sectionIndex) => (
              <Card sx={{ p: 2, mt: 1 }} ref={sectionIndex === sections.length - 1 ? lastRef : undefined}>
                <div>
                  <Stack direction={"row"} columnGap={2} alignItems={"center"} mt={2}>
                    <TextField
                      label="Section name"
                      value={sectionItem?.name || ""}
                      onChange={(e) => {
                        sections[sectionIndex].name = e.target.value;
                        setSections([...sections]);
                      }}
                      InputProps={{
                        startAdornment: (
                          <Button variant="text" disabled sx={{ ml: -3, mr: -2 }}>
                            <Typography sx={{ color: "#000" }}>{sectionIndex + 1}.</Typography>
                          </Button>
                        ),
                      }}
                      error={errorMsg && !sectionItem?.name}
                      helperText={`${errorMsg && !sectionItem?.name ? errorMsg + "section name" : ""}`}
                      fullWidth
                      size="small"
                    />
                    <Tooltip title="Add new task">
                      <Button
                        disabled={reorderItems}
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{ height: "self", whiteSpace: "nowrap" }}
                        autoCapitalize="none"
                        size="medium"
                        onClick={() => {
                          const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
                          tasksObject[sectionItem.id].push({
                            task: null,
                            images: [],
                            index: tasksObject[sectionItem.id].length + "",
                            id: randomNumber + sectionIndex + "",
                          });
                          setTasksObject({ ...tasksObject });
                          setErrorMsg(null);
                        }}
                      >
                        New Task
                      </Button>
                    </Tooltip>
                    <Tooltip title={"Remove section"}>
                      <DeleteIcon
                        color="error"
                        onClick={() => {
                          setSelectedSectionIndex(sectionIndex);
                          setShowDialog(true);
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Stack>
                  <Stack>
                    <div>
                      {tasksObject[sectionItem.id] &&
                        tasksObject[sectionItem.id].map((taskItem, taskIndex) => (
                          <div id={sectionItem.id + taskItem.id + ""} key={sectionItem.id + taskItem.id + ""}>
                            <Card sx={{ p: 1, mt: 1, mb: 3 }}>
                              <Stack flexDirection={"row"} gap={1} alignItems={"center"} sx={{ flex: 1 }}>
                                <TextField
                                  label="Task name"
                                  value={taskItem?.task || ""}
                                  onChange={(e) => {
                                    const taskToUpdate = { ...tasksObject };
                                    taskToUpdate[sectionItem.id][taskIndex].task = e.target.value;
                                    setTasksObject({ ...tasksObject });
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <Button variant="text" disabled sx={{ ml: -3, mr: -2 }}>
                                        <Typography sx={{ color: "#000" }}>{taskIndex + 1}.</Typography>
                                      </Button>
                                    ),
                                  }}
                                  error={errorMsg && !taskItem?.task}
                                  helperText={`${errorMsg && !taskItem?.task ? errorMsg + "task" : ""}`}
                                  fullWidth
                                  sx={{ flex: 9, mx: 2 }}
                                  size="small"
                                />

                                {!templateImages.length && taskItem?.images && (
                                  <Stack mt={2} direction={"row"} gap={2}>
                                    {[...taskItem?.images].map((imageItem, index) => (
                                      <div
                                        key={imageItem + index}
                                        onClick={() => {
                                          const imageArray = [
                                            { message: imageItem, type: asyncStorageValues?.allTypes?.TYPE_OF_CONTENT.IMAGE },
                                          ];
                                          setTaskImageList(imageArray);
                                          setShowDialogView(true);
                                        }}
                                      >
                                        <AuthorizedImage path={imageItem} width={70} height={70} />
                                      </div>
                                    ))}
                                  </Stack>
                                )}
                                <TaskImagesComponent taskItem={taskItem} sectionItem={sectionItem} templateTaskImages={templateImages} />
                                <Tooltip title={"Attach Image"}>
                                  <IconButton
                                    component="span"
                                    onClick={() => {
                                      setSelectedTaskSection({
                                        taskItem,
                                        sectionItem,
                                      });
                                      taskItem?.images?.length ? setShowMultipleImageAlert(true) : setDragOrCopyLinkToModal(true);
                                    }}
                                  >
                                    <AttachFileIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Remove task"}>
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      tasksObject[sectionItem.id].splice(taskIndex, 1);
                                      setTasksObject({ ...tasksObject });
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Card>
                          </div>
                        ))}
                    </div>
                    {tasksObject[sectionItem.id] && tasksObject[sectionItem.id].length > 2 ? (
                      <Stack justifyContent={"flex-end"} direction={"row"} columnGap={2} alignItems={"center"} mt={2}>
                        <Tooltip title="Add new task">
                          <Button
                            disabled={reorderItems}
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ height: "self", whiteSpace: "nowrap" }}
                            autoCapitalize="none"
                            size="medium"
                            onClick={() => {
                              const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
                              tasksObject[sectionItem.id].push({
                                task: null,
                                images: [],
                                index: tasksObject[sectionItem.id].length + "",
                                id: randomNumber + sectionIndex + "",
                              });
                              setTasksObject({ ...tasksObject });
                              setErrorMsg(null);
                            }}
                          >
                            New Task
                          </Button>
                        </Tooltip>
                        <Tooltip title={"Remove section"}>
                          <DeleteIcon
                            color="error"
                            onClick={() => {
                              setSelectedSectionIndex(sectionIndex);
                              setShowDialog(true);
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Stack>
                    ) : null}
                  </Stack>
                </div>
              </Card>
            ))}
          </div>
        )}
        <div>
          {sections &&
          sections.length &&
          (sections.length > 2 ||
            sections.filter((section) => tasksObject[section.id] && tasksObject[section.id].length >= 5).length >= 1) ? (
            <Card sx={{ p: 3, mt: 2 }}>
              <Stack alignItems={"flex-end"}>
                <Stack direction={"row"} gap={2}>
                  <Tooltip title={"Add section"}>
                    <Button
                      disabled={reorderItems}
                      startIcon={<AddIcon />}
                      sx={{ height: "self" }}
                      autoCapitalize="none"
                      variant="contained"
                      onClick={() => {
                        const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
                        setSections([
                          ...sections,
                          {
                            name: "",
                            id: randomNumber + "",
                            index: sections.length + "",
                          },
                        ]);
                        setTasksObject({ ...tasksObject, [randomNumber + ""]: [] });
                        lastRef.current.scrollIntoView();
                      }}
                    >
                      New section
                    </Button>
                  </Tooltip>
                  <Tooltip title={"Save template"}>
                    <Button
                      disabled={allTemplates.isLoading}
                      startIcon={<SaveIcon />}
                      sx={{ height: "self" }}
                      autoCapitalize="none"
                      variant="contained"
                      onClick={() => validate()}
                    >
                      Save
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Card>
          ) : null}
        </div>
      </Box>
    </div>
  );
};

export default AddEditNewTemplate;
