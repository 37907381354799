import React from "react";
import { useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import WidgetContainer from "../WidgetContainer";
import { Paper, Stack, Typography } from "@mui/material";

const ServiceTicketRatingForCustomer = () => {
  const { serviceTicketRatingForCustomer, loading } = useSelector((state) => state.dashboard);

  const { "No of tickets": totalTickets, Title, ...ratings } = serviceTicketRatingForCustomer.data;

  const chartData = Array.from({ length: 5 }, (_, i) => ({
    star: i + 1,
    count: ratings[i + 1] || 0,
  })).filter((data) => data.count > 0);

  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    // <WidgetContainer title={Title}>
    <Paper elevation={8} sx={{ padding: 1, height: 550, overflow: "hidden" }}>
      <Stack alignItems={"center"} py={3}>
        <Typography fontWeight={"700"}>Service Hours by Ticket Category</Typography>
      </Stack>
      <div style={{ color: "#555", fontSize: "1.0em", marginLeft: "70px", marginTop: "3px", marginBottom: "20px" }}>
        Total Tickets : {totalTickets}
      </div>
      {totalTickets === 0 ? (
        <div
          style={{
            color: "#888",
            fontSize: "1.2em",
            width: "650px",
            height: "260px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No tickets
        </div>
      ) : (
        <div style={{ height: 450 }}>
          <ResponsiveContainer width="99%" height="90%">
            <ComposedChart margin={{ top: 26, right: 20, bottom: 20, left: 20 }} data={chartData} style={{ background: "transparent" }}>
              <CartesianGrid stroke="transparent" />
              <XAxis dataKey="star" tickFormatter={(value) => `${value} Star`} tick={{ fontSize: 14 }} />
              <YAxis />
              <Tooltip />
              <Legend content={() => null} />
              <Bar
                dataKey="count"
                barSize={30}
                fill="#ffc107"
                minPointSize={10}
                label={{
                  position: "top",
                  fill: "#000",
                  fontSize: 12,
                  formatter: (value) => `${value} tickets`,
                }}
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </Paper>
  );
};

export default ServiceTicketRatingForCustomer;
