import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  DialogTitle,
  TextField,
  Button,
  Dialog,
  Box,
  Alert,
  Typography,
  Checkbox,
  Stack,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
//import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { readAsyncStorageValues } from "../../features/common/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { getAppUsers, createUser, deleteUser, updateUser, emailActiveAppUsers } from "../../features/users/actions";
import SearchIcon from "@mui/icons-material/Search";
import { useParams, useNavigate } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import { faIR } from "@mui/x-date-pickers";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PersonOffRounded, PersonRounded } from "@mui/icons-material";

import { exportToCsv } from "../../utils/utilFunctions";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [indianStates, setIndianStates] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState("");
  const [deleteUserId, setDeleteUserId] = useState("");
  const [listData, setListData] = useState([]);
  const { register: editFormRegister, handleSubmit: editHandleSubmit, reset: editFormReset } = useForm();
  const [formData1, setFormData1] = useState({
    enabled: true,
    userName: "",
    password: "",
  });
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [addUsers, setAddUsers] = useState(true);
  const [oldSvcCoordinator, setOldSvcCoordinator] = useState("");
  const { appUsersList } = useSelector((state) => state.users);
  const { loading: commonLoading, indianStateDropdownOptions, asyncStorageValues } = useSelector((state) => state.common);

  useEffect(() => {
    const fetch = async () => {
      dispatch(readAsyncStorageValues());
      dispatch(getAppUsers());
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (appUsersList?.data) {
      setListData(appUsersList.data);
    }
  }, [appUsersList]);
  useEffect(() => {
    if (!commonLoading) setIndianStates(indianStateDropdownOptions);
  }, [commonLoading, indianStateDropdownOptions]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const inputChangeHandler = (event) => {
    const mobileNumberRegex = /^[0-9+().\-\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let id = event.target.id;
    let value = event.target.value;
    if (id === "name") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, name: "Name is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, name: null }));
      }
    } else if (id === "username") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, username: "username is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, username: null }));
      }
    } else if (id === "number") {
      if (!mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, number: "Please enter a valid Phone Number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, number: null }));
      }
    } else if (id === "email") {
      if (!emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, email: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, email: null }));
      }
    } else if (id === "password") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, password: "Password is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, password: null }));
      }
    } else if (id === "confirmPassword") {
      if (addUsers && !value) {
        validationErrors.confirmPassword = "Confirm Password is required";
        setValidationErrors((prevState) => ({ ...prevState, confirmPassword: "Confirm Password is required" }));
      } else if (formData1.password === value) {
        validationErrors.confirmPassword = " Password do not match";
        setValidationErrors((prevState) => ({ ...prevState, confirmPassword: "Password do not match" }));
      }
    }
  };

  // const deleteIconHandle = (data) => {
  //   setDeleteModal(true);
  //   setDeleteUserName(data.row.name);
  //   setDeleteUserId(data.row.userId);
  // };
  const deleteCustomer = async () => {
    let collection = [];
    collection.push(deleteUserId);
    let data = {};
    data.userIds = collection;
    dispatch(deleteUser(data)).then((res) => {
      dispatch(getAppUsers());
      toast.success(`${deleteUserName} user is deleted`, {
        onClose: () => {
          setDeleteModal(false);
        },
        autoClose: 2000,
      });
    });
  };
  const handleCloseEdit = () => {
    setEditForm(false);
    setFormData1({});
    editFormReset();
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          startIcon={<EditIcon />}
          // variant="contained"
          color="primary"
          size="small"
          style={{ marginRight: 10 }}
          onClick={() => {
            editFormReset();
            editUser(params);
            setAddUsers(false);
          }}
        ></Button>
        {"  "}
        {/* <IconButton
          color="secondary"
          onClick={() => {
            deleteIconHandle(params);
          }}
        >
          <DeleteIcon style={{ color: "grey" }} />
        </IconButton> */}
      </strong>
    );
  };

  // const renderDetailsButton = (params) => {
  //   const isActive = params.row.enabled; // Assuming you have a property like 'enabled' indicating the user's status
  //   return (
  //     <Stack direction={"row"}>
  //       <Button
  //         startIcon={<EditIcon />}
  //         color="primary"
  //         size="small"
  //         onClick={() => {
  //           editFormReset();
  //           editUser(params);
  //           setShowResetPassword(false);
  //           setAddUsers(false);
  //         }}
  //       ></Button>
  //       {activeTab === tabNames.ALL && (
  //         <Tooltip title={isActive ? "Active user" : "Inactive user"}>
  //           {isActive ? (
  //             <PersonRounded color="primary" sx={{ fontSize: 21 }} />
  //           ) : (
  //             <PersonOffRounded color="primary" sx={{ fontSize: 21 }} />
  //           )}
  //         </Tooltip>
  //       )}
  //     </Stack>
  //   );
  // };

  const editUser = (data) => {
    setEditForm(true);
    setOldSvcCoordinator(data.row.svcCoordinator);
    setFormData1(data.row);
  };

  // const handleCloseDelete = () => {
  //   setDeleteModal(false);
  // };

  const dataToBE = (data) => {
    let svcCoord = formData1.svcCoordinator
      ? isNaN(formData1.svcCoordinator)
        ? appUsersList?.data.filter((coord) => coord.userName == formData1.svcCoordinator)[0].userId
        : formData1.svcCoordinator
      : null;

    let collection = {
      state: formData1.state.label ? formData1.state.label : formData1.state,
      ...(addUsers ? null : { userId: formData1.userId }),
      email: data.email,
      mobileNumber: data.number,
      name: data.name,
      userName: data.username,
      userRole: formData1.userRole,
      password: data.password,
      department:
        asyncStorageValues.departments && asyncStorageValues.departments.filter((dept) => formData1.department == dept.name)[0].id,
      ...(formData1.userRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_ENGINEER
        ? addUsers
          ? { svcCoordinator: svcCoord }
          : { svcCoordinator: svcCoord, oldSvcCoordinator: oldSvcCoordinator }
        : null),
      active: formData1.enabled ? true : false,
    };

    const userExists = appUsersList?.data.some((item) => item.userName.toLowerCase() === data.username.toLowerCase());

    if (userExists && addUsers) return toast.error("Username already exists!");
    if (
      userExists &&
      appUsersList.data.some((item) => item.userId !== formData1.userId && item.userName.toLowerCase() == data.username.toLowerCase())
    ) {
      return toast.error("Username already exists!");
    } else {
      if (formData1?.userId) collection.userId = parseInt(formData1.userId);
      handleUserAction(collection);
    }
  };

  const handleUserAction = (collection) => {
    const action = addUsers ? createUser : updateUser;
    dispatch(action(collection)).then((res) => {
      if (res.payload?.data) {
        let updatedList;
        if (addUsers) {
          updatedList = [...listData, res.payload.data];
        } else {
          updatedList = listData.map((item) => (item.userId === collection.userId ? res.payload.data : item));
        }
        setListData(updatedList);

        toast.success(`User ${formData1.userId ? "updated" : "added"} successfully!`, {
          autoClose: 1000,
        });
        handleCloseEdit();
        window.location.reload();
      } else {
        toast.error("Error occurred while updating the user");
      }
    });
  };

  const editFormSubmit = async (data) => {
    const mobileNumberRegex = /^[0-9+().\-\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      data.name &&
      data.email &&
      data.username &&
      data.number &&
      mobileNumberRegex.test(data.number) &&
      emailRegex.test(data.email) &&
      formData1.state &&
      formData1.userRole
    ) {
      if (addUsers && !data.password) {
        setValidationErrors((prevState) => ({ ...prevState, password: "Password is required" }));
      } else if ((addUsers && !data.confirmPassword) || (data.password && !data.confirmPassword)) {
        setValidationErrors((prevState) => ({ ...prevState, confirmPassword: "Confirm Password is required" }));
      } else if (data.confirmPassword) {
        let confirmPasswordError =
          (addUsers && data.password !== data.confirmPassword) || (data.password && data.password !== data.confirmPassword)
            ? "Password do not match"
            : null;
        setValidationErrors((prevState) => ({
          ...prevState,
          password: null,
          confirmPassword: confirmPasswordError || dataToBE(data),
        }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, password: null }));
        dataToBE(data);
      }
    } else {
      toast.warning("Please enter all the required fields(marked with *)", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleAddClick = (data) => {
    setAddUsers(true);
    setShowResetPassword(true);
    editFormReset();
    editUser();
  };
  const readBindwelUser = async () => {
    toast.success(`Please wait we are sending you active user list ...`);
    dispatch(emailActiveAppUsers());
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 0.2 },
    { field: "email", headerName: "Email Address", flex: 0.2 },
    { field: "userMobileNumber", headerName: "Phone Number", flex: 0.15 },
    { field: "state", headerName: "State", flex: 0.3 },
    { field: "userRole", headerName: "User Role", flex: 0.25 },
    { field: "userName", headerName: "Username", flex: 0.2 },
    {
      field: "col6",
      headerName: "",
      flex: 0.1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
    },
  ];

  const tabNames = {
    ALL: "All",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  };
  const [activeTab, setActiveTab] = useState(tabNames.ALL);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    if (appUsersList?.data) {
      if (newValue === tabNames.ACTIVE) setListData(appUsersList.data.filter((item) => item.enabled));
      else if (newValue === tabNames.INACTIVE) setListData(appUsersList.data.filter((item) => !item.enabled));
      else setListData(appUsersList.data);
    }
  };
  function CustomTabPanel(props) {
    const { value } = props;
    return (
      <div hidden={value !== activeTab} id={`simple-tabpanel-${value}`}>
        {value === activeTab && (
          <Box sx={{ height: 600, px: 4 }}>
            <DataGrid
              rows={listData.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableColumnMenu
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
              getRowId={(row) => `${row.name}-${row.dateCreated}`}
            />
          </Box>
        )}
      </div>
    );
  }

  const handleClearSearch = () => setSearchTerm("");
  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id,Username, Name, Email Address, Mobile Number, State, Role, Department"];
    const keys = ["id", "userName", "name", "email", "userMobileNumber", "state", "userRole", "department"];
    exportToCsv(headers, keys, listData, activeTab + "_Users");
  };

  return appUsersList?.loading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div>
      <Stack sx={{ mt: 3, mx: 5 }} direction={"row"} justifyContent={"space-between"}>
        <TextField
          style={{ minWidth: 300, boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton edge="end" onClick={handleClearSearch} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            color="primary"
            onClick={handleAddClick}
            sx={{ textTransform: "capitalize", height: 50 }}
          >
            Add
          </Button>
          <Button variant="contained" color="primary" onClick={readBindwelUser} sx={{ textTransform: "capitalize", height: 50, ml: 2 }}>
            Email Active User List
          </Button>

          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
            onClick={downloadCsv}
            // disabled={allCustomersByState.customers.length ? false : true}
          >
            Export to CSV
          </Button>
        </Box>
      </Stack>
      <Dialog open={editForm} alignSelf="center" onClose={handleCloseEdit} sx={{ width: 750, marginLeft: "30%" }}>
        <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
          {addUsers ? "Add User" : "Edit User"}
          <IconButton onClick={handleCloseEdit} style={{ position: "absolute", top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          component="form"
          onSubmit={editHandleSubmit(editFormSubmit)}
          noValidate
          sx={{ mt: 1, padding: 10, paddingTop: 0, alignSelf: "center" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={formData1.name}
            id="name"
            label="Name"
            name="name"
            error={Boolean(validationErrors.name)}
            helperText={validationErrors.name ? "Name is Required" : ""}
            autoFocus
            {...editFormRegister("name")}
            onChange={inputChangeHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={formData1.userName ? formData1.userName : null}
            id="new-username"
            label="Username"
            name="new-username"
            error={Boolean(validationErrors.username)}
            helperText={validationErrors.username ? validationErrors.username : ""}
            autoComplete="new-username"
            autoFocus
            {...editFormRegister("username")}
            onChange={inputChangeHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={formData1.email}
            id="email"
            label="Email"
            name="email"
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email ? validationErrors.email : ""}
            autoFocus
            {...editFormRegister("email")}
            onChange={inputChangeHandler}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={formData1.userMobileNumber}
            id="number"
            label="Mobile Number"
            name="number"
            error={Boolean(validationErrors.number)}
            helperText={validationErrors.number ? validationErrors.number : ""}
            autoFocus
            {...editFormRegister("number")}
            onChange={inputChangeHandler}
          />
          <Autocomplete
            disablePortal
            required
            fullWidth
            name="state"
            id="state"
            options={indianStates}
            onChange={(event, value) => {
              if (!value) {
                setValidationErrors((prevState) => ({ ...prevState, state: "State is required" }));
                setFormData1({ ...formData1, state: null });
              } else {
                setValidationErrors((prevState) => ({ ...prevState, state: null }));
                setFormData1({ ...formData1, state: value });
              }
            }}
            value={formData1.state}
            sx={{ alignSelf: "center", paddingTop: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State*"
                error={Boolean(validationErrors.state)}
                helperText={validationErrors.state ? validationErrors.state : ""}
              />
            )}
            // {...editFormRegister("state")}
          />
          <Autocomplete
            disablePortal
            required
            fullWidth
            name="department"
            id="department"
            options={asyncStorageValues.departments && asyncStorageValues.departments.map((department) => department.name)}
            // getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              if (!value) {
                setValidationErrors((prevState) => ({ ...prevState, department: "Department is required" }));
                setFormData1({ ...formData1, department: null });
              } else {
                setValidationErrors((prevState) => ({ ...prevState, department: null }));
                setFormData1({ ...formData1, department: value });
              }
            }}
            value={formData1.department}
            sx={{ alignSelf: "center", paddingTop: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department*"
                error={Boolean(validationErrors.department)}
                helperText={validationErrors.department ? validationErrors.department : ""}
              />
            )}
            // {...editFormRegister("state")}
          />
          <Autocomplete
            disablePortal
            required
            fullWidth
            name="userRole"
            id="userRole"
            options={
              asyncStorageValues && asyncStorageValues.allTypes
                ? Object.values(asyncStorageValues?.allTypes?.USER_ROLES || {}).filter(
                    (role) => !["ROLE_ADMIN", "ROLE_CUSTOMER", "ROLE_SERVICE_OTHER", "ROLE_COMMERCIAL_AND_ACCOUNTS"].includes(role)
                  )
                : []
            }
            onChange={(event, value) => {
              if (!value) {
                setValidationErrors((prevState) => ({ ...prevState, userRole: "User Role is required" }));
                setFormData1({ ...formData1, userRole: null });
              } else {
                setValidationErrors((prevState) => ({ ...prevState, userRole: null }));
                setFormData1({ ...formData1, userRole: value });
              }
            }}
            value={formData1.userRole}
            sx={{ alignSelf: "center", paddingTop: 3, paddingBottom: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Role*"
                error={Boolean(validationErrors.userRole)}
                helperText={validationErrors.userRole ? "userRole is Required" : ""}
              />
            )}
            // {...editFormRegister("userRole")}
          />
          {formData1.userRole == asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_ENGINEER ? (
            <Autocomplete
              disablePortal
              required
              fullWidth
              name="svcCoordinator"
              id="svcCoordinator"
              options={
                appUsersList?.data
                  .filter((obj) => obj.userRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_COORDINATOR && obj.enabled)
                  .map((obj) => obj.userName) || []
              }
              // getOptionLabel={(option) => option?.name}
              // isOptionEqualToValue={(option, value) => option.userId === value.userId}
              onChange={(event, value) => {
                if (!value) {
                  setValidationErrors((prevState) => ({ ...prevState, svcCoordinator: "Service Coordinator is required" }));
                  setFormData1({ ...formData1, svcCoordinator: null });
                } else {
                  setValidationErrors((prevState) => ({ ...prevState, svcCoordinator: null }));
                  setFormData1({ ...formData1, svcCoordinator: value });
                }
              }}
              value={
                formData1.svcCoordinator
                  ? isNaN(formData1.svcCoordinator)
                    ? formData1.svcCoordinator
                    : appUsersList.data.filter((coord) => coord.userId == formData1.svcCoordinator)[0].userName
                  : ""
              }
              sx={{ alignSelf: "center", paddingTop: 3, paddingBottom: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service Coordinator*"
                  error={Boolean(validationErrors.svcCoordinator)}
                  helperText={validationErrors.svcCoordinator ? "Service Coordinator is Required" : ""}
                />
              )}
            />
          ) : null}

          {formData1.userId && (
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Checkbox
                checked={showResetPassword}
                onChange={(event) => setShowResetPassword(!showResetPassword)}
                inputProps={{ "aria-label": "controlled" }}
                defaultChecked={showResetPassword}
              />
              <Typography>Set password</Typography>
            </Stack>
          )}
          {showResetPassword && (
            <TextField
              autoFocus
              margin="normal"
              required
              id="new-password"
              name="new-password"
              label="Password"
              // value={formData1.password}
              onChange={inputChangeHandler}
              fullWidth
              sx={{ paddingBottom: 1.5 }}
              placeholder={addUsers ? "Password*" : "Password"}
              type={showPassword ? "text" : "password"}
              error={Boolean(validationErrors.password)}
              autoComplete="new-password"
              helperText={validationErrors.password ? validationErrors.password : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ position: "absolute", top: 10, right: 10 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...editFormRegister("password")}
            />
          )}
          {showResetPassword && (
            <TextField
              autoFocus
              margin="dense"
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password*"
              // value={formData1.password}
              onChange={inputChangeHandler}
              fullWidth
              placeholder={addUsers ? "Confirm Password*" : "Confirm Password"}
              type={showConfirmPassword ? "text" : "password"}
              error={Boolean(validationErrors.confirmPassword)}
              helperText={validationErrors.confirmPassword ? validationErrors.confirmPassword : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ position: "absolute", top: 10, right: 10 }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...editFormRegister("confirmPassword")}
            />
          )}

          <Stack flexDirection={"row"} alignItems={"center"}>
            <Checkbox
              checked={formData1.enabled}
              onChange={(event) => setFormData1({ ...formData1, enabled: event.target.checked })}
              inputProps={{ "aria-label": "controlled" }}
              defaultChecked={formData1.enabled}
            />
            <Typography>Active</Typography>
          </Stack>

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
        </Box>
      </Dialog>
      {/* <Dialog open={deleteModal} alignSelf="center" onClose={handleCloseDelete}>
        <Alert
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div id="alert-dialog-title">Confirm Action</div>
          <div id="alert-dialog-description">Do you want to delete this user {deleteUserName} ?</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleCloseDelete}>No</Button>
            <Button onClick={deleteCustomer}>Yes</Button>
          </div>
        </Alert>
      </Dialog> */}
      <Box sx={{}}>
        <>
          <Tabs value={activeTab} onChange={handleChange} centered>
            <Tab label={tabNames.ALL} value={tabNames.ALL} />
            <Tab label={tabNames.ACTIVE} value={tabNames.ACTIVE} />
            <Tab label={tabNames.INACTIVE} value={tabNames.INACTIVE} />
          </Tabs>
        </>
        <CustomTabPanel value={tabNames.ALL} />
        <CustomTabPanel value={tabNames.ACTIVE} />
        <CustomTabPanel value={tabNames.INACTIVE} />
      </Box>
      <ToastContainer />
    </div>
  );
};
export default User;
