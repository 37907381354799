import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Stack, Box, Paper, CircularProgress, useTheme, List, ListItemButton, ListItemText } from "@mui/material";
import { readReportPathByServiceTicketId } from "../../../features/servicetickets/actions";
import ViewReportPDF from "../../../components/ViewReportPDF";

import api from "../../../app/api";

const ViewReport = () => {
  const dispatch = useDispatch();
  const { serviceTicketId } = useParams();
  const { reportPathByServiceTicketId } = useSelector((state) => state.serviceTickets);

  useEffect(() => {
    if (serviceTicketId) {
      dispatch(readReportPathByServiceTicketId(serviceTicketId));
    }
  }, [dispatch, serviceTicketId]);

  const handleDownload = async (pdfPath) => {
    try {
      const response = await api.get(`/serviceapi/writeImageToClient?location=${pdfPath}`, {
        responseType: "blob",
      });
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      const pdfFileName = pdfPath.split("/").pop();
      link.setAttribute("download", pdfFileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack flexDirection="row" justifyContent="center" pr={2}>
      <Box ml={10}>
        <Paper elevation={8} sx={{ width: 750, padding: 1 }}>
          <ViewReportPDF path={reportPathByServiceTicketId?.data?.path || null} scale={1} showPages={true} />
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => handleDownload(reportPathByServiceTicketId?.data?.path)}
              color="primary"
              variant="contained"
              sx={{ width: "200px" }}
            >
              Download
            </Button>
          </div>
        </Paper>
      </Box>
    </Stack>
  );
};

export default ViewReport;
