import React, { useEffect, useState } from "react";
import { Stack, TextField, InputAdornment, IconButton, Box, CircularProgress, Tooltip, Button } from "@mui/material";
import { readPaidCompletedServiceTicket } from "../../features/servicetickets/actions";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";

const PaidTickets = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { paidCompletedServiceTicket } = useSelector((state) => state.serviceTickets);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(readPaidCompletedServiceTicket());
  }, [dispatch]);


  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));


  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="View">
          <Button
            startIcon={<ZoomInIcon />}
            color="primary"
            size="large"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/all-service-ticket-view/" + params.row.id);
            }}
          />
        </Tooltip>
      </strong>
    );
  }


  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "openDate",
      headerName: "Date Created",
      flex: 0.1,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        const dateCreated = params.row?.dateCreated;
        if (dateCreated) {
          return moment(dateCreated).format("DD MMM YYYY");
        }
      },
    },
    { field: "customerName", headerName: "Customer Name", flex: 0.3 },
    { field: "ticketCategory", headerName: "Category", flex: 0.3 },
    { field: "status", headerName: "Status", flex: 0.2 },
    {
      field: "col5",
      headerName: "",
      flex: 0.2,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const filteredData = paidCompletedServiceTicket.data.filter((ticket) => {
    return !searchTerm || Object.values(ticket).join(" ").toLowerCase().includes(searchTerm.toLowerCase());
  });

  const rows = filteredData.map((ticket) => ({
    id: ticket.ticketId,
    dateCreated: ticket.openDate,
    customerName: ticket.customerName,
    ticketCategory: ticket.ticketCategory,
    status: ticket.status,
  }));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <Stack justifyContent="center" sx={{ mx: 3, my: 4 }}>
      <Stack direction={"row"} ml={4} alignItems="center" justifyContent={"space-between"}>
        <TextField
          style={{ minWidth: 350 }}
          label="search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton edge="end" onClick={handleClearSearch} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 10, marginTop: 12, paddingRight: "1%", overflow: "auto" }}>
        {loading || paidCompletedServiceTicket.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={rows}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default PaidTickets;
