import React, { useEffect, useState, useMemo } from "react";
import { Checkbox, FormControlLabel, Typography, Box, Button, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import moment from "moment";
import { getServiceTicketsForNotification } from "../../features/servicetickets/actions";
import { useDispatch, useSelector } from "react-redux";
import { readAsyncStorageValues } from "../../features/common/actions";

const NotifyPage = () => {
  const today = moment().format("DD MMM YYYY");
  const tomorrow = moment().add(1, "days").format("DD MMM YYYY");
  const [selectedDate, setSelectedDate] = useState("today");
  const [selectedTickets, setSelectedTickets] = useState([]);
  const dispatch = useDispatch();

  // Accessing values directly from Redux state
  const { serviceTicketNoftification} = useSelector((state) => state.serviceTickets);
  const { asyncStorageValues } = useSelector((state) => state.common);

  useEffect(() => {
    // Fetch service tickets and async storage values
    dispatch(getServiceTicketsForNotification());
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  useEffect(() => {
    // Clear selected tickets when the selected date changes
    setSelectedTickets([]);
  }, [selectedDate]);

  // Filtering tickets based on selected date
  const filteredData = serviceTicketNoftification.data?.filter(ticket => {
    const scheduledDate = moment(ticket.scheduledDate).format("DD MMM YYYY");
    return selectedDate === "today" ? scheduledDate === today : scheduledDate === tomorrow;
  });

  // Memoizing the list of engineers
  const engineers = useMemo(() => {
    return asyncStorageValues.appUsers.filter(
      (user) => user.userRole === "ROLE_SERVICE_ENGINEER"
    );
  }, [asyncStorageValues.appUsers]);

  // Function to get engineer's name based on assigneeId
  const getEngineerName = (assigneeIds) => {
    return assigneeIds.map(assigneeId => {
      const engineer = engineers.find((engineer) => engineer.userId === assigneeId);
      return engineer?.name || "Unknown Engineer";
    });
  };

  const handleTicketSelect = (ticketId) => {
    setSelectedTickets((prevSelected) =>
      prevSelected.includes(ticketId) ? prevSelected.filter((id) => id !== ticketId) : [...prevSelected, ticketId]
    );
  };

  const handleSelectAll = () => {
    setSelectedTickets(
      selectedTickets.length === filteredData.length ? [] : filteredData.map(ticket => ticket.ticketId)
    );
  };

  const handleSendNotification = () => {
    const selectedDetails = filteredData.filter(ticket => selectedTickets.includes(ticket.ticketId));
    let msgs = "";

    // Sort selected details by engineer name
    selectedDetails.sort((a, b) => {
      const engineerNamesA = getEngineerName(a.assigneeId).join(", ");
      const engineerNamesB = getEngineerName(b.assigneeId).join(", ");
      return engineerNamesA.localeCompare(engineerNamesB);
    });

    // Create a counter to number the tickets
    let ticketCounter = 1;

    selectedDetails.forEach((ticket) => {
      const engineerNames = getEngineerName(ticket.assigneeId);
      const allEngineerNames = engineerNames.join(", ");
      msgs += `${ticketCounter}. ${allEngineerNames} - ${ticket.customerName} - ${ticket.modelName} - ${ticket.ticketCategory} - ${ticket.paymentType}\n\n`;
      ticketCounter++; // Increment the counter for each ticket
    });

    const url = `https://web.whatsapp.com/send?text=${encodeURIComponent(msgs)}`;
    window.open(url, "_blank");
  };

  // Set to keep track of displayed engineer names
  const displayedEngineers = new Set();

  return (
    <Box m={2}>
      <Typography variant="h5" mb={2}>Notify Engineers</Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={selectedDate === "today"}
            onChange={() => setSelectedDate("today")}
          />
        }
        label={`Today ${today}`}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedDate === "tomorrow"}
            onChange={() => setSelectedDate("tomorrow")}
          />
        }
        label={`Tomorrow ${tomorrow}`}
      />
    
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filteredData?.length > 0 && selectedTickets.length === filteredData.length}
              onChange={handleSelectAll}
            />
          }
          label="Select All"
        />
      </Box>

      {serviceTicketNoftification.loading ? (
        <CircularProgress />
      ) : filteredData && filteredData.length > 0 ? (
        <List>
          {filteredData
            .map((ticket) => {
              const engineerNames = getEngineerName(ticket.assigneeId);
              const firstEngineerName = engineerNames[0]; // Get the first engineer
              return {
                ...ticket,
                engineerName: firstEngineerName,
              };
            })
            .filter(ticket => {
              const isEngineerDisplayed = displayedEngineers.has(ticket.engineerName);
              if (!isEngineerDisplayed) {
                displayedEngineers.add(ticket.engineerName);
                return true; // Include this ticket in the list
              }
              return false; // Skip tickets from engineers that have already been displayed
            })
            .sort((a, b) => a.engineerName.localeCompare(b.engineerName)) // Sort alphabetically by engineer name
            .map((ticket) => (
              <ListItem
                key={ticket.ticketId} // Use ticketId as the key for better uniqueness
                divider
                style={{ backgroundColor: '#C6E3EC', marginBottom: '12px', padding: '12px 16px' }}
              >
                <Checkbox
                  checked={selectedTickets.includes(ticket.ticketId)}
                  onChange={() => handleTicketSelect(ticket.ticketId)}
                  style={{ marginRight: '8px' }}
                />
                <ListItemText primary={ticket.engineerName} style={{ margin: 0 }} />
              </ListItem>
            ))}
        </List>
      ) : (
        <Typography>No tickets scheduled for {selectedDate === "today" ? "today" : "tomorrow"}.</Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendNotification}
        disabled={selectedTickets.length === 0}
      >
        Send Notification
      </Button>
    </Box>
  );
};

export default NotifyPage;
