import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { readServiceTimeLog } from "../../../features/servicetickets/actions";

const Timelogs = ({ ticketId }) => {
  const dispatch = useDispatch();
  const { serviceTimeLogs } = useSelector((state) => state.serviceTickets);
  const { asyncStorageValues } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(readServiceTimeLog(ticketId));
  }, [dispatch]);

  const minutes_to_hhmm = (numberOfMinutes) => {
    var hh =
      numberOfMinutes.years() * (365 * 24) + numberOfMinutes.months() * (30 * 24) + numberOfMinutes.days() * 24 + numberOfMinutes.hours();
    var mm = numberOfMinutes.minutes();
    return hh + "h " + mm + " m";
  };

  if (serviceTimeLogs.loading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      <Paper sx={{ borderRadius: 2, mb: 0.8, px: 2, py: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}>
        <Typography variant="h6" sx={{ mb: 0.8 }} fontWeight={600}>
          Timelogs
        </Typography>
        <List>
          {serviceTimeLogs.data.length ? (
            serviceTimeLogs.data.map((timeLog) => (
              <Paper
                sx={{
                  mt: 2,
                  px: 2,
                  py: 1,
                  border: "1px solid #ddd",
                  boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
                  borderRadius: 2,
                }}
              >
                <Stack flexDirection="row" justifyContent={"space-between"}>
                  <Typography>
                    {asyncStorageValues && Object.keys(asyncStorageValues).length
                      ? asyncStorageValues.appUsers.find((user) => user.userName === timeLog.serviceEngineer).name
                      : ` [${timeLog.serviceEngineer}]`}
                    {` `}
                  </Typography>
                  <Typography>
                    Hours :{" "}
                    {timeLog.endTime
                      ? minutes_to_hhmm(
                          moment.duration(
                            moment(moment(timeLog.endTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm").diff(
                              moment(moment(timeLog.startTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm"),
                              "minutes"
                            ),
                            "minutes"
                          )
                        )
                      : " -"}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" justifyContent={"space-between"}>
                  <Typography>Start time : {timeLog?.startTime ? moment(timeLog.startTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                  <Typography>End time : {timeLog?.endTime ? moment(timeLog.endTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                </Stack>
              </Paper>
            ))
          ) : (
            <Typography>No timelogs.</Typography>
          )}
        </List>
      </Paper>
    </div>
  );
};

export default Timelogs;
