import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import AverageResponseTime from "../../components/AverageResponseTime";
import AverageResolutionTime from "../../components/AverageResolutionTime";

const ResponseResolutionDashboard = () => {
  return (
    <Stack sx={{ pb: 30 }}>
      <Typography variant="h4" align="center" sx={{ mb: 3, mt: 3 }}>
        Data Driven Dashboard
      </Typography>
      <Stack justifyContent="center" direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 3 }}>
        <AverageResolutionTime />
        <AverageResponseTime />
      </Stack>
    </Stack>
  );
};

export default ResponseResolutionDashboard;
